import React from 'react'
import { useState, useEffect } from 'react';
import Navbar from '../../components/Navbar/Navbar'
import { Grid, Box } from '@material-ui/core';
import "animate.css/animate.min.css";
import "./ErrorPage.scss";
import ServerDown from "../../img/server-down.svg";
import Footer from '../../components/Footer/Footer';
import ScrollAnimation from 'react-animate-on-scroll';
import { history } from '../../helpers';
import TiltAnimation from '../../components/TiltAnimation/TiltAnimation';

export function ErrorWrapper(props) {

    useEffect(() => {
        if (!props.disableRedirect) {
            setTimeout(() => {
                history.push('/');
            }, 6000);
        }
    }, []);

    let message = 'Something went wrong !';
    if (!props.message) {
        message = (new URLSearchParams(history.location.search).get("m"));
        if (message && message.length) {
            message = atob(message);
        } else {
            history.push('/');
        }
    } else {
        message = props.message;
    }

    return (
        <ScrollAnimation className="flex-grow-1 d-flex flex-column" animateOnce delay={0} animateIn="animate__fadeIn">
            <Grid container className={"flex-grow-1 d-flex"}>
                <Grid item xs={12} md={6} >
                    <div className="error-message-wrapper">
                        <h1>Error !</h1>
                        <h3>{message}</h3>
                        {
                            !props.disableRedirect &&
                            <h6 className="mt-2">Redirecting to home ...</h6>
                        }

                    </div>
                </Grid>
                <Box
                    component={Grid}
                    item
                    xs={6}
                    display={{ xs: "none", md: "flex" }}
                >
                    <TiltAnimation image={ServerDown} className={'jumbotron error-hero flex-grow-1'}>

                    </TiltAnimation>
                </Box>
            </Grid>

        </ScrollAnimation>
    )
}


export default function ErrorPage(props) {



    return (

        <div className={'container-fluid m-0 p-0 d-flex flex-column h-100'}>
            <header className={'d-flex flex-column justify-content-center align-items-center'}>
                <div className="header-margin"></div>
                <Navbar />
            </header>
            {/* Hero */}
            <div className="d-flex w-100 flex-grow-1 justify-content-center align-items-center">
                <div className="w-85">
                    <ErrorWrapper message={props.message} />
                </div>
            </div>

            {/* Footer */}
            <Footer />

        </div>

    )
}
