import { authHeader, history } from '../helpers';
import { API_URL } from "../config/urls";

export const userService = {
    login,
    logout,
    register,
    getAll,
    getById,
    forgotPassword,
    resetPassTokenValidate,
    resetPassWithToken,
    resetPassword,
    getProfile,
    update,
    delete: _delete,
    getCurrent,
};



function login(email, password) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password })
    };

    return fetch(`${API_URL}/app/users/login`, requestOptions)
        .then(handleResponse)
        .then(user => {
            if (user.user) {
                user = { ...user, ...user.user };
            }
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('user', JSON.stringify(user));

            return user;
        });
}

function logout(silent = false) {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
    if (!silent) {
        if (history.location.pathname.indexOf('/login') < 0) {
            history.push(`/login`);
        }
    }
}


function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${API_URL}/app/users`, requestOptions).then(handleResponse);
}

function getById(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${API_URL}/app/users/${id}`, requestOptions).then(handleResponse);
}

function getCurrent() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${API_URL}/app/users/currentUser`, requestOptions).then(handleResponse);
}

function getProfile(token = null) {

    let _authHeader;
    if (token) {
        _authHeader = { 'Authorization': token };
    }

    const requestOptions = {
        method: 'GET',
        headers: _authHeader ? _authHeader : authHeader()
    };

    return fetch(`${API_URL}/app/users/me`, requestOptions).then(handleResponse)
        .then(user => {
            if (user.user) {
                user = { ...user, ...user.user };
            }
            user.token = token;
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('user', JSON.stringify(user));
            return user;
        });
}



function register(user) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(user)
    };

    return fetch(`${API_URL}/app/users/register`, requestOptions).then(handleResponse);
}

function resetPassWithToken(token, newPassword) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ newPassword })
    };

    return fetch(`${API_URL}/app/users/resetPassword/${token || 'asd'}`, requestOptions).then(handleResponse);
}

function resetPassword(currentPassword, newPassword) {
    console.log(currentPassword, newPassword);
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify({ currentPassword, newPassword })
    };

    return fetch(`${API_URL}/app/users/changePassword`, requestOptions).then(handleResponse);
}




function forgotPassword(email) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' }
    };

    return fetch(`${API_URL}/app/users/forgotPass/${email || 'asd'}`, requestOptions).then(handleResponse);
}


function resetPassTokenValidate(token) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' }
    };

    return fetch(`${API_URL}/app/users/verifyResetToken/${token || 'asd'}`, requestOptions).then(handleResponse);
}




function update(user) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(user)
    };

    return fetch(`${API_URL}/app/users/${user.id}`, requestOptions).then(handleResponse);;
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    return fetch(`${API_URL}/app/users/${id}`, requestOptions).then(handleResponse);
}

function handleResponse(response) {
    // console.log();
    return response.text().then(text => {

        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                if (window.location.href.indexOf("login?t") >= 0) {
                    window.location.href = "/login";
                } else {
                    window.location.reload(true);
                }

            }

            const error = (data && data.msg) || data.errors || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}