import { authHeader, authToken } from '../helpers';
import { userService } from './user.service';
import { API_URL } from "../config/urls";

export const cvService = {
    getAll,
    getAllByUser,
    getById,
    update,
    create,
    downloadByIdLinkGenerate,
    delete: _delete
};


function getAll(q = {}) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${API_URL}/app/cv?${q.limit ? 'limit=' + q.limit : ''}`, requestOptions).then(handleResponse);
}

function getAllByUser() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${API_URL}/app/users/me/cv-list`, requestOptions).then(handleResponse);
}



function getById(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${API_URL}/app/cv/${id}`, requestOptions).then(handleResponse);
}

function downloadByIdLinkGenerate(id) {
    return `${API_URL}/app/cv/${id}/download?t=${btoa(authToken())}`;
}




function update(cvId, cv) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(cv)
    };

    return fetch(`${API_URL}/app/cv/${cvId}`, requestOptions).then(handleResponse);;
}

function create(cv) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(cv)
    };

    return fetch(`${API_URL}/app/cv`, requestOptions).then(handleResponse);;
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    return fetch(`${API_URL}/app/cv/${id}`, requestOptions).then(handleResponse);
}

function handleResponse(response) {
    // console.log();
    return response.text().then(text => {
        let data = text;
        try {
            data = data && JSON.parse(data);
        } catch (err) { }

        if (!response.ok) {

            if (response.status === 401) {
                // auto logout if 401 response returned from api
                userService.logout();
                if (window.location.href.indexOf("login?t") >= 0) {
                    window.location.href = "/login";
                } else {
                    window.location.reload(true);
                }

            }


            const error = (data && data.msg) || data.errors || (typeof data === 'string' ? data : null) || response.responseText || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}