import { userConstants } from '../constants';
import { userService } from '../services';
import { alertActions } from './';
import { history } from '../helpers';

export const userActions = {
    login,
    loginWithToken,
    logout,
    register,
    getAll,
    forgotPassword,
    isAdmin,
    delete: _delete,
    getCurrent,
};


function isAdmin(user) {
    return user && user.role === 2;
}

function loginWithToken(token) {
    console.log("here token is ", token);
    return dispatch => {
        userService.getProfile(token)
            .then(
                user => {
                    dispatch(success(user));
                    history.push('/cv/list');
                },
                error => {
                    if (error && Array.isArray(error)) {
                        dispatch(failure(error));
                    } else {
                        dispatch(failure(error));
                        dispatch(alertActions.error(error.toString()));
                    }
                }
            );
    };

    function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(errors) { return { type: userConstants.LOGIN_TOKEN_FAILURE, errors } }
}

function login(email, password, from) {
    return dispatch => {
        dispatch(request({ email }));

        userService.login(email, password)
            .then(
                user => {
                    dispatch(success(user));
                    history.push(from);
                },
                error => {
                    if (error && Array.isArray(error)) {
                        dispatch(failure(error));
                    } else {
                        dispatch(failure(error));
                        dispatch(alertActions.error(error.toString()));
                    }
                }
            );
    };

    function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(errors) { return { type: userConstants.LOGIN_FAILURE, errors } }
}

function forgotPassword(email) {
    return dispatch => {
        dispatch(request({ email }));

        userService.forgotPassword(email)
            .then(
                () => {
                    dispatch(success());
                    history.push('/login');
                    dispatch(alertActions.success(`Email sent to ${email} if it is registered.`));
                },
                error => {
                    if (error && Array.isArray(error)) {
                        dispatch(failure(error));

                    } else {
                        dispatch(failure(error));
                        dispatch(alertActions.error(error.toString()));
                    }
                }
            );
    };

    function request(user) { return { type: userConstants.FORGOT_PASS_REQUEST, user } }
    function success(user) { return { type: userConstants.FORGOT_PASS_SUCCESS, user } }
    function failure(errors) { return { type: userConstants.FORGOT_PASS_FAILURE, errors } }
}

function logout(silent = false) {
    console.log("word here", silent);
    userService.logout(silent);
    return { type: userConstants.LOGOUT };
}

function register(user) {
    return dispatch => {
        dispatch(request(user));

        userService.register(user)
            .then(
                user => {
                    dispatch(success());
                    history.push('/login');
                    dispatch(alertActions.success('Registration successful.'));
                },
                errors => {
                    if (errors && Array.isArray(errors)) {
                        dispatch(failure(errors));
                    } else {
                        dispatch(failure(errors));
                        dispatch(alertActions.error(errors.toString()));
                    }
                }
            );
    };

    function request(user) { return { type: userConstants.REGISTER_REQUEST, user } }
    function success(user) { return { type: userConstants.REGISTER_SUCCESS, user } }
    function failure(errors) { return { type: userConstants.REGISTER_FAILURE, errors } }
}

function getAll() {
    return dispatch => {
        dispatch(request());

        userService.getAll()
            .then(
                users => dispatch(success(users)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: userConstants.GETALL_REQUEST } }
    function success(users) { return { type: userConstants.GETALL_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GETALL_FAILURE, error } }
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
    return dispatch => {
        dispatch(request(id));

        userService.delete(id)
            .then(
                user => dispatch(success(id)),
                error => dispatch(failure(id, error.toString()))
            );
    };

    function request(id) { return { type: userConstants.DELETE_REQUEST, id } }
    function success(id) { return { type: userConstants.DELETE_SUCCESS, id } }
    function failure(id, error) {
        return {
            type: userConstants.DELETE_FAILURE, id, error
        };
    }
}

function getCurrent() {
    return dispatch => {

        userService.getCurrent()
            .then(
                user => dispatch(success(user)),
                error => dispatch(failure(error.toString()))
            );
    };

    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.GET_CURRENT_FAILURE, error } }
}