import { userConstants } from '../constants';

export function forgotPass(state = {}, action) {
    switch (action.type) {
        case userConstants.FORGOT_PASS_REQUEST:
            return { resetting: true };
        case userConstants.FORGOT_PASS_SUCCESS:
            return {
                resetting: false
            };
        case userConstants.FORGOT_PASS_FAILURE:
            return {
                errors: action.errors,
                resetting: false,
            };
        default:
            return state
    }
}