import React from 'react'
import { useRef, useEffect } from 'react';


export default function TiltAnimation({ className, children, image, maxX, maxY }) {

    const el = useRef(null);

    const mouseMove = (e) => {

        if (el) {
            let elWidth = parseInt(window.getComputedStyle(el.current).width);
            let elHeight = parseInt(window.getComputedStyle(el.current).width);
            let left = el.current.getBoundingClientRect().left;
            let top = el.current.getBoundingClientRect().top;
            let leftPositionCursor = ((e.clientX - left) * 100) / elWidth;
            let topPositionCursor = ((e.clientY - top) * 100) / elHeight;
            el.current.style.backgroundPosition = `${Math.min((maxX || 100), leftPositionCursor / 2)}% ${Math.min((maxY || 100), topPositionCursor / 2)}%`;
        }


    }


    return (
        <div ref={el} onMouseMove={mouseMove} className={className || ''} style={{ backgroundImage: `url(${image})`, backgroundSize: 'initial' }}>
            {children}
        </div>
    )
}
