import { authHeader, authToken } from '../helpers';
import { API_URL } from "../config/urls";

export const postService = {
    getAll,
    getById,
    update,
    create,
    uploadImage,
    delete: _delete
};


function getAll(q = {}) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${API_URL}/app/posts?${q.limit ? 'limit=' + q.limit : ''}`, requestOptions).then(handleResponse);
}

function uploadImage(file) {
    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.open('POST', '/app/posts/image');
        xhr.setRequestHeader('Authorization', authToken());
        const data = new FormData();
        data.append('image', file);
        xhr.send(data);
        xhr.addEventListener('load', () => {
            console.log(xhr.responseText);
            const response = JSON.parse(xhr.responseText);
            resolve(response);
        });
        xhr.addEventListener('error', () => {
            const error = JSON.parse(xhr.responseText);
            reject(error);
        });
    })
}

function getById(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${API_URL}/app/posts/${id}`, requestOptions).then(handleResponse);
}


function update(post) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(post)
    };

    return fetch(`${API_URL}/app/posts/${post._id}`, requestOptions).then(handleResponse);;
}

function create(post) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(post)
    };

    return fetch(`${API_URL}/app/posts`, requestOptions).then(handleResponse);;
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    return fetch(`${API_URL}/app/posts/${id}`, requestOptions).then(handleResponse);
}

function handleResponse(response) {
    // console.log();
    return response.text().then(text => {
        let data = text;
        try {
            data = data && JSON.parse(data);
        } catch (err) { }

        if (!response.ok) {
            const error = (data && data.msg) || data.errors || (typeof data === 'string' ? data : null) || response.responseText || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}