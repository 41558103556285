import { API_URL } from "../config/urls";

export const siteService = {
    contactFormSubmit
};

function contactFormSubmit(data) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };

    return fetch(`${API_URL}/app/contact`, requestOptions)
        .then(handleResponse)
}


function handleResponse(response) {
    return response.text().then(text => {
        if (!response.ok) {
            let error;
            try {
                const data = text && JSON.parse(text);
                error = (data && data.msg) || data.errors || response.statusText;
            } catch (err) {
                error = text;
            } finally {
                return Promise.reject(error);
            }
        }
        return text;
    });
}