import { authHeader, authToken } from '../helpers';
import { userService } from './user.service';
import { API_URL } from "../config/urls";

export const subscriptionServices = {
    getConfig,
    create,
};

function getConfig() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${API_URL}/app/subscription/config/paypal`, requestOptions).then(handleResponse);
}

function create(subObj) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(subObj)
    };

    return fetch(`${API_URL}/app/subscription`, requestOptions).then(handleResponse);;
}

function handleResponse(response) {
    // console.log();
    return response.text().then(text => {
        let data = text;
        try {
            data = data && JSON.parse(data);
        } catch (err) { }

        if (!response.ok) {

            if (response.status === 401) {
                // auto logout if 401 response returned from api
                userService.logout();
                if (window.location.href.indexOf("login?t") >= 0) {
                    window.location.href = "/login";
                } else {
                    window.location.reload(true);
                }

            }


            const error = (data && data.msg) || data.errors || (typeof data === 'string' ? data : null) || response.responseText || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}