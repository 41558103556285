import './App.scss';
import React, { useEffect, Suspense, lazy } from 'react';
import { Route, Switch, Redirect, Router, HashRouter } from 'react-router-dom';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Snackbar } from '@material-ui/core';
import { history } from './helpers';
import { alertActions } from './actions';
import { PrivateRoute } from './components/PrivateRoute';
import { AdminRoute } from './components/AdminRoute';
import LazyLoader from './components/LazyLoader/LazyLoader';
import NotFound from './pages/NotFound/NotFound';
// import Home from './pages/Home/Home';
// import Login from './pages/Login/Login';
// import Signup from './pages/Signup/Signup';
// import BlogPage from './pages/Blog/BlogPage';
// import SinglePost from './pages/Blog/SinglePost';
// import CvWizard from './pages/CvWizard/CvWizard';
// import PostForm from './pages/admin/ManagePosts/PostForm';
// import ManagePosts from './pages/admin/ManagePosts/ManagePosts';
// import ErrorPage from './pages/error/ErrorPage';
// import ForgotPassword from './pages/ForgotPassword/ForgotPassword';
// import CvSample from './pages/CvSample/CvSample';
// import ResetPassword from './pages/ResetPassword/ResetPassword';
// import SingleCV from './pages/SingleCV/SingleCV';
// import CvList from './pages/CvList/CvList';
// import CvChooseTemplate from './pages/CvChooseTemplate/CvChooseTemplate';
// import UpdatePassword from './pages/UpdatePassword/UpdatePassword';
// import Contact from './pages/Contact/Contact';
import { firebase } from "./config/firebase";

const Login = lazy(() => import('./pages/Login/Login'));
const Home = lazy(() => import('./pages/Home/Home'));
const Signup = lazy(() => import('./pages/Signup/Signup'));
const BlogPage = lazy(() => import('./pages/Blog/BlogPage'));
const SinglePost = lazy(() => import('./pages/Blog/SinglePost'));
const CvWizard = lazy(() => import('./pages/CvWizard/CvWizard'));
const PostForm = lazy(() => import('./pages/admin/ManagePosts/PostForm'));
const ManagePosts = lazy(() => import('./pages/admin/ManagePosts/ManagePosts'));
const ErrorPage = lazy(() => import('./pages/error/ErrorPage'));
const ForgotPassword = lazy(() => import('./pages/ForgotPassword/ForgotPassword'));
const CvSample = lazy(() => import('./pages/CvSample/CvSample'));
const ResetPassword = lazy(() => import('./pages/ResetPassword/ResetPassword'));
const SingleCV = lazy(() => import('./pages/SingleCV/SingleCV'));
const CvList = lazy(() => import('./pages/CvList/CvList'));
const CvChooseTemplate = lazy(() => import('./pages/CvChooseTemplate/CvChooseTemplate'));
const UpdatePassword = lazy(() => import('./pages/UpdatePassword/UpdatePassword'));
const Contact = lazy(() => import('./pages/Contact/Contact'));
const About = lazy(() => import('./pages/About/About'));
const PrivacyPolicy = lazy(() => import('./pages/PrivacyPolicy/PrivacyPolicy'));
const TermsAndConditions = lazy(() => import('./pages/TermsAndConditions/TermsAndConditions'));
const SubscriptionPage = lazy(() => import('./pages/Subscription/SubscriptionPage'));
const SubscriptionPayment = lazy(() => import('./pages/Subscription/SubscriptionPayment'));



const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#c64756'
    },
    secondary: {
      main: '#281618'
    }
  }
});

function App() {

  const alert = useSelector(state => state.alert);
  const dispatch = useDispatch();


  const handleClose = () => {
    dispatch(alertActions.clear());
  }

  useEffect(() => {
    firebase.configure();
    history.listen((location, action) => {
      // clear alert on location change
      dispatch(alertActions.clear());
    });

    //one time on page load
    if (!(alert && alert.message)) {
      dispatch(alertActions.clear());
    }

  }, []);

  return (
    <MuiThemeProvider theme={theme}>
      <div className="App">
        {alert.message &&
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            open={true}
            autoHideDuration={6000}
            onClose={handleClose}
            message={alert.message}
            action={
              <Button color="primary" size="small" onClick={handleClose}>
                Close
              </Button>
            }
          />
          //<div className={`alert ${alert.type}`}>{alert.message}</div>
        }
        <Router history={history}>
          <Suspense fallback={<div><LazyLoader /></div>}>

            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/login" component={Login} />
              <Route exact path="/signup" component={Signup} />
              <Route exact path="/forgotpass" component={ForgotPassword} />
              <PrivateRoute exact path="/profile/updatePassword" component={UpdatePassword} />
              <PrivateRoute exact path="/cv/new" component={CvWizard} />
              <PrivateRoute exact path="/cv/samples" component={CvWizard} />
              <PrivateRoute exact path="/cv/list" component={CvList} />
              <PrivateRoute exact path="/cv/:cvId/edit" component={CvWizard} />
              <PrivateRoute exact path="/cv/:cvId/chooseTemplate" component={CvChooseTemplate} />
              <PrivateRoute exact path="/cv/:cvId" component={SingleCV} />
              <Route exact path="/resetPassword/:token" component={ResetPassword} />
              <Route exact path="/blog" component={BlogPage} />

              <Route exact path="/cv-preview/:cvId" component={CvSample} />
              <Route exact path="/cv-sample/:cvId" component={CvSample} />
              <Route exact path="/contact" component={Contact} />
              <Route exact path="/about" component={About} />
              <Route exact path="/error" component={ErrorPage} />
              <AdminRoute exact path="/blog/managePost" component={ManagePosts} />
              <AdminRoute exact path="/blog/managePost/:postId" component={PostForm} />
              <Route exact path="/blog/posts/:postId" component={SinglePost} />
              <Route exact path="/privacy-policy" component={PrivacyPolicy} />
              <Route exact path="/terms-and-conditions" component={TermsAndConditions} />
              <Route exact path="/subscription" component={SubscriptionPage} />
              <PrivateRoute exact path="/subscription/subscribe/:subscriptionId" component={SubscriptionPayment} />

              <Route path="/" component={NotFound} />
            </Switch>

          </Suspense>
        </Router>
      </div>
    </MuiThemeProvider>
  );
}

export default App;
