import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, FacebookAuthProvider, GoogleAuthProvider, signInWithPopup } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyAKRVMHeJyAvBkxlE_oMbw0tfMvtrkoPI4",
    authDomain: "buildurcv-e7916.firebaseapp.com",
    projectId: "buildurcv-e7916",
    storageBucket: "buildurcv-e7916.appspot.com",
    messagingSenderId: "705372121380",
    appId: "1:705372121380:web:a27fc1bc4e20223870ebb1",
    measurementId: "G-83Y06QYGW8"
};

export const facebookProvider = new FacebookAuthProvider();
export const googleProvider = new GoogleAuthProvider();

let auth;

class Firebase {
    configure = () => {
        const app = initializeApp(firebaseConfig);
        const analytics = getAnalytics(app);
        auth = getAuth();
    } 
    
    socialMediaAuth = (provider) => {
        return signInWithPopup(auth, provider)
        .then(res => {
            console.log("Login Response", res);
            return res.user;
        })
        .catch(err => {
            console.log("Login Error", err);
        })
    }
}

export const firebase = new Firebase();