// // classic
// import ClassicTemplate from './ClassicTemplate/ClassicTemplate';
// import ClassicTemplateImage from './ClassicTemplate/ClassicTemplate.png';
// // classic red

// import ClassicRedTemplate from './ClassicRedTemplate/ClassicRedTemplate';
// import ClassicRedTemplateImage from './ClassicRedTemplate/ClassicRedTemplate.png';

// Imperial Red
import ImperialRedTemplate from './ImperialRed/ImperialRedTemplate';
import ImperialRedTemplateImage from './ImperialRed/ImperialRedTemplate.png';

// Orange Breeze
import OrangeBreezeTemplate from './OrangeBreeze/OrangeBreezeTemplate';
import OrangeBreezeTemplateImage from './OrangeBreeze/OrangeBreeze.png';

// Kino
import KinoTemplate from './Kino/KinoTemplate';
import KinoTemplateTemplateImage from './Kino/KinoTemplate.png';

// Kino
import CoalTemplate from './Coal/CoalTemplate';
import CoalTemplateImage from './Coal/CoalTemplate.png';

// Nectar
import NectarTemplate from './Nectar/NectarTemplate';
import NectarTemplateImage from './Nectar/NectarTemplate.png';

// Charcoal
import CharcoalTemplate from './Charcoal/CharcoalTemplate';
import CharcoalTemplateImage from './Charcoal/CharcoalTemplate.png';

// Levender
import LavenderTemplate from './Lavender/LavenderTemplate';
import LavenderTemplateImage from './Lavender/LavenderTemplate.png';


// Palette
import PaletteTemplate from './Palette/PaletteTemplate';
import PaletteTemplateImage from './Palette/PaletteTemplate.png';

// Palette
import PollenTemplate from './Pollen/PollenTemplate';
import PollenTemplateImage from './Pollen/PollenTemplate.jpg';


export default [
    // {
    //     title: 'classic',
    //     content: ClassicTemplate,
    //     image: ClassicTemplateImage
    // },

    // {
    //     title: 'classic-red',
    //     content: ClassicRedTemplate,
    //     image: ClassicRedTemplateImage
    // },

    {
        title: 'imperial-red',
        content: ImperialRedTemplate,
        image: ImperialRedTemplateImage
    },

    {
        title: 'orange-breeze',
        content: OrangeBreezeTemplate,
        image: OrangeBreezeTemplateImage
    },

    {
        title: 'kino',
        content: KinoTemplate,
        image: KinoTemplateTemplateImage
    },

    {
        title: 'Coal',
        content: CoalTemplate,
        image: CoalTemplateImage
    },

    {
        title: 'Nectar',
        content: NectarTemplate,
        image: NectarTemplateImage
    },
    {
        title: 'Charcoal',
        content: CharcoalTemplate,
        image: CharcoalTemplateImage
    },
    {
        title: 'Lavender',
        content: LavenderTemplate,
        image: LavenderTemplateImage
    },
    {
        title: 'Palette',
        content: PaletteTemplate,
        image: PaletteTemplateImage
    },
    {
        title: 'Pollen',
        content: PollenTemplate,
        image: PollenTemplateImage
    }

]