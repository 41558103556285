import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import ErrorPage from '../pages/error/ErrorPage';


const getUser = () => {
    if (!localStorage.getItem('user')) {
        return null;
    }

    let u = localStorage.getItem('user');
    return JSON.parse(u);
}

function AdminRoute({ component: Component, roles, ...rest }) {
    return (
        <Route {...rest} render={props => {
            if (!getUser()) {
                console.log("redirecting me as well ?");
                // not logged in so redirect to login page with the return url
                return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
            }


            if (getUser().role !== 2) {
                console.log("redirecting");
                let newRoute = '/error';
                return <ErrorPage message={"Not authorized to view this page."} />
            }


            // logged in so return component
            return <Component {...props} />
        }} />
    );
}

export { AdminRoute };