import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import rootReducer from '../reducers';
import checkTokenExpirationMiddleware from './token-validator';

const loggerMiddleware = createLogger();

let middleware = [];
if (process.env.NODE_ENV === 'production') {
    middleware = [...middleware, thunkMiddleware];
} else {
    middleware = [...middleware, thunkMiddleware, loggerMiddleware];
}

export const store = createStore(
    rootReducer,
    applyMiddleware(...middleware)
);