import React from 'react'
import "./PollenTemplate.scss";
import { MdEmail, MdPhone } from 'react-icons/md';
import { FaFacebook, FaGithub, FaGlobe, FaLinkedin, FaMapMarked, FaTwitter } from 'react-icons/fa';
import { BsStarFill, BsStarHalf, BsStar } from 'react-icons/bs';
import { Timeline, TimelineItem, TimelineSeparator, TimelineConnector, TimelineContent, TimelineDot, TimelineOppositeContent } from '@material-ui/lab';
import { IoMdSchool } from 'react-icons/io';
import { ImOffice } from 'react-icons/im';
import moment from 'moment';
const formatDate = (d) => {
    return moment(d).format('yyyy');
}

const isMobile = window.innerWidth <= 768;

const skillLabels = {
    0.5: 'Useless',
    1: 'Useless+',
    1.5: 'Poor',
    2: 'Poor+',
    2.5: 'Ok',
    3: 'Ok+',
    3.5: 'Good',
    4: 'Good+',
    4.5: 'Excellent',
    5: 'Excellent+',
};

const langLevels = {
    'basic': 25,
    'conversational': 50,
    'fluent': 75,
    'native': 100
}

const WorkExperienceTimeline = ({ employments }) => {
    return (
        <React.Fragment>
            <Timeline className="obcv-t">
                {
                    (employments || []).map(emp =>
                        <TimelineItem>
                            <TimelineOppositeContent className="obcv-t-content-wrapper-l">
                                <div className="obcv-t-l">
                                    <div className="obcv-t-l-d">
                                        {`${formatDate(emp.startDate)}`}
                                    </div>

                                </div>
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineDot />
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent className="obcv-t-content-wrapper-r">
                                <div className="obcv-t-l-c">
                                    <span>{emp.employer || ''}</span> /  <span> {emp.jobTitle || ''}</span>
                                </div>
                                <div className="obcv-t-r-c">
                                    {emp.description || ''}
                                </div>
                            </TimelineContent>
                        </TimelineItem>
                    )
                }
            </Timeline>
        </React.Fragment>
    )
}

const EducationTimeLine = ({ educations }) => {
    return (
        <React.Fragment>
            <Timeline className="obcv-t">
                {
                    (educations || []).map(ed =>
                        <TimelineItem>
                            <TimelineOppositeContent className="obcv-t-content-wrapper-l">
                                <div className="obcv-t-l">
                                    <div className="obcv-t-l-d">
                                        {`${formatDate(ed.startDate)}`}
                                    </div>

                                </div>
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineDot />
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent className="obcv-t-content-wrapper-r">
                                <div className="obcv-t-l-c">
                                    <span>{ed.schoolName || ''}</span> /  <span> {ed.degree || ''}</span>
                                </div>
                                <div className="obcv-t-r-c">
                                    {ed.details || ''}
                                </div>
                            </TimelineContent>
                        </TimelineItem>
                    )
                }
            </Timeline>
        </React.Fragment>
    )
}

export default function PollenTemplate(props) {
    const data = props.data || {};

    const formatDate = (d) => {
        return moment(d).format('MMM, yyyy');
    }

    React.useEffect(() => {
        Array.from(document.querySelectorAll(".pollen-top-tri") || []).map(e => {
            e.style.borderLeftWidth = window.getComputedStyle(e.parentElement).width;
        });
        Array.from(document.querySelectorAll(".pollen-bottom-tri") || []).map(e => {
            e.style.borderRightWidth = window.getComputedStyle(e.parentElement).width;
        });
    }, []);


    const showSocialAccounts = () => {
        if (data && data.socialAccounts) {
            return data.socialAccounts.github || data.socialAccounts.facebook || data.socialAccounts.linkedIn || data.socialAccounts.twitter || data.socialAccounts.personalSite
        }

        return false
    }

    const getAddress = () => {
        return (`${data.intro.country ? data.intro.country.name : ''}, ${data.intro.city ? data.intro.city : ''}, ${data.intro.state ? data.intro.state : ''}, ${data.intro.zip ? data.intro.zip : ''}`).trim().replace(/^,|,$/g, '').trim();
    }



    const getStars = (stars) => {
        let stars_ = [];
        for (let i = 1; i <= stars; i++) {
            stars_.push(<span className="pollen-rect pollen-rect-full" />);
        }
        let remainders = 5 - stars;
        if (remainders.toString().indexOf(".5") >= 0) {
            stars_.push(<span className="pollen-rect pollen-rect-half" />)
            remainders = remainders.toString().substr(0, remainders.toString().indexOf("."));
        }

        for (let i = 1; i <= remainders; i++) {
            stars_.push(<span className="pollen-rect pollen-rect-emp" />)
        }
        return stars_;
    }

    return (
        <div id="pollen-template-cv" ref={props.ref}>
            <div className="pollen-cv-template--header">
                <div className="pollen-color-cols-holder">
                    <div className="p-cc-h-w"></div>
                    <div className="p-cc-h-b"></div>
                    <div className="p-cc-h-y">
                        <div className="pollen-cv-template--header--name">
                            <h1>{data.intro && data.intro.firstName ? data.intro.firstName : null} {data.intro && data.intro.lastName ? data.intro.lastName : null}</h1>

                            <div>
                                {data.intro.profession ? data.intro.profession.split(" ").map(e => {
                                    return <span>{e} </span>
                                }) : null}
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div className="pollen-profile-section">
                <div className="pollen-image-holder">
                    <img src="" />
                </div>
                <div className="pollen-top-tri"></div>
                <div className="pollen-objective-holder">
                    {
                        data.intro && data.intro.objective && data.intro.objective.length > 0 &&
                        <>
                            <div className="pollen-cv-template--header-sub">
                                <div className="pollen-cv-template--header-sub-heading y-bg">
                                    <h3>Objective</h3>
                                </div>
                                <div className="pollen-cv-template--header-sub-content">{data.intro.objective}</div>
                            </div>
                        </>
                    }
                </div>
                <div className="pollen-contact-section">
                    <div className="pollen-cv-template--single-section">
                        <div className="pollen-cv-template--single-section--header y-bg">
                            <h3 className="pollen-cv-template--single-section--heading">Contacts</h3>
                        </div>
                        <div className="pollen-cv-template--single-section--content">
                            <div className="pollen-cv-template--single-section--icon-list">
                                {
                                    data.intro && data.intro.phone &&
                                    <div className="pollen-cv-template--single-section--icon-list-single">
                                        <span className="pollen-cv-template--single-section--icon-list-single-icon">
                                            <MdPhone size={isMobile ? 10 : 20} />
                                        </span>
                                        <span className="pollen-cv-template--single-section--icon-list-single-content">{data.intro.phone}</span>
                                    </div>
                                }

                                {
                                    data.intro && data.intro.email &&
                                    <div className="pollen-cv-template--single-section--icon-list-single">
                                        <span className="pollen-cv-template--single-section--icon-list-single-icon">
                                            <MdEmail size={isMobile ? 10 : 20} />
                                        </span>
                                        <span className="pollen-cv-template--single-section--icon-list-single-content">{data.intro.email}</span>
                                    </div>
                                }

                                {
                                    data.intro && (data.intro.country || data.intro.city || data.intro.state) &&
                                    <div className="pollen-cv-template--single-section--icon-list-single">
                                        <span className="pollen-cv-template--single-section--icon-list-single-icon">
                                            <FaMapMarked size={isMobile ? 10 : 20} />
                                        </span>
                                        <span className="pollen-cv-template--single-section--icon-list-single-content">{getAddress()}</span>
                                    </div>
                                }
                            </div>

                        </div>
                    </div>

                </div>
                <div className="pollen-bottom-tri"></div>
            </div>



            <div className="content-wrapper">
                <div className="left-sidebar">



                    {
                        showSocialAccounts() &&
                        <>
                            <div className="pollen-cv-template--single-section">
                                <div className="pollen-cv-template--single-section--header">
                                    <h3 className="pollen-cv-template--single-section--heading">Social</h3>
                                    <div className="pollen-cv-template--single-section--header-underline"></div>
                                </div>
                                <div className="pollen-cv-template--single-section--content">
                                    <div className="pollen-cv-template--single-section--icon-list">
                                        {
                                            data.socialAccounts.facebook &&
                                            <div className="pollen-cv-template--single-section--icon-list-single">
                                                <span className="pollen-cv-template--single-section--icon-list-single-icon">
                                                    <FaFacebook size={isMobile ? 10 : 20} />
                                                </span>
                                                <span className="pollen-cv-template--single-section--icon-list-single-content">{data.socialAccounts.facebook}</span>
                                            </div>
                                        }

                                        {
                                            data.socialAccounts.twitter &&
                                            <div className="pollen-cv-template--single-section--icon-list-single">
                                                <span className="pollen-cv-template--single-section--icon-list-single-icon">
                                                    <FaTwitter size={isMobile ? 10 : 20} />
                                                </span>
                                                <span className="pollen-cv-template--single-section--icon-list-single-content">{data.socialAccounts.twitter}</span>
                                            </div>
                                        }

                                        {
                                            data.socialAccounts.linkedIn &&
                                            <div className="pollen-cv-template--single-section--icon-list-single">
                                                <span className="pollen-cv-template--single-section--icon-list-single-icon">
                                                    <FaLinkedin size={isMobile ? 10 : 20} />
                                                </span>
                                                <span className="pollen-cv-template--single-section--icon-list-single-content">{data.socialAccounts.linkedIn}</span>
                                            </div>
                                        }

                                        {
                                            data.socialAccounts.github &&
                                            <div className="pollen-cv-template--single-section--icon-list-single">
                                                <span className="pollen-cv-template--single-section--icon-list-single-icon">
                                                    <FaGithub size={isMobile ? 10 : 20} />
                                                </span>
                                                <span className="pollen-cv-template--single-section--icon-list-single-content">{data.socialAccounts.github}</span>
                                            </div>
                                        }

                                        {
                                            data.socialAccounts.personalSite &&
                                            <div className="pollen-cv-template--single-section--icon-list-single">
                                                <span className="pollen-cv-template--single-section--icon-list-single-icon">
                                                    <FaGlobe size={isMobile ? 10 : 20} />
                                                </span>
                                                <span className="pollen-cv-template--single-section--icon-list-single-content"><a href={data.socialAccounts.personalSite}>{data.socialAccounts.personalSite.replace(/https:\/\//ig, '').replace(/http:\/\//ig, '')}</a></span>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </>
                    }


                    {
                        data.skills && data.skills.length > 0 &&
                        <>
                            <div className="pollen-cv-template--single-section">
                                <div className="pollen-cv-template--single-section--header">
                                    <h3 className="pollen-cv-template--single-section--heading">Skills</h3>
                                    <div className="pollen-cv-template--single-section--header-underline"></div>
                                </div>
                                <div className="pollen-cv-template--single-section--content">
                                    <div className="pollen-cv-template--single-section--skill-list">
                                        {
                                            data.skills.map(s =>
                                                <div className="pollen-cv-template--single-section--skill-list-single">
                                                    <span className="pollen-cv-template--single-section--skill-list-single-skill">
                                                        {s.title || ''}
                                                    </span>
                                                    <span className="pollen-cv-template--single-section--skill-list-single-stars">{getStars(s.grip || 0)}</span>
                                                </div>
                                            )
                                        }

                                    </div>
                                </div>
                            </div>
                        </>
                    }


                    {
                        data.languages && data.languages.length > 0 &&
                        <>
                            <div className="pollen-cv-template--single-section">
                                <div className="pollen-cv-template--single-section--header">
                                    <h3 className="pollen-cv-template--single-section--heading">Languages</h3>
                                    <div className="pollen-cv-template--single-section--header-underline"></div>
                                </div>
                                <div className="pollen-cv-template--single-section--content">
                                    <div className="pollen-cv-template--single-section--language-list">
                                        {
                                            data.languages.map(lan =>
                                                <div className="pollen-cv-template--single-section--language-list-single">
                                                    <div className="progress-circle-container">
                                                        <div className={`progress-circle progress-${langLevels[lan.level || 'conversational']}`}><span>{langLevels[lan.level || 'conversational']}</span></div>
                                                    </div>
                                                    <span className="pollen-cv-template--single-section--language-list-single-content">
                                                        {lan.title || ''}
                                                    </span>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </>
                    }

                    {
                        data.keyAssets && data.keyAssets.length > 0 &&
                        <>
                            <div className="pollen-cv-template--single-section">
                                <div className="pollen-cv-template--single-section--header">
                                    <h3 className="pollen-cv-template--single-section--heading">Key Assets</h3>
                                    <div className="pollen-cv-template--single-section--header-underline"></div>
                                </div>
                                <div className="pollen-cv-template--single-section--content">
                                    <div className="pollen-cv-template--single-section--keyassets-list">
                                        {
                                            data.keyAssets.map(ka =>
                                                <div className="pollen-cv-template--single-section--skill-list-single">
                                                    <span className="pollen-cv-template--single-section--skill-list-single-skill">
                                                        {ka.title || ''}
                                                    </span>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                </div>
                <div className="main-content">

                    {
                        data.employments && data.employments.length > 0 &&
                        <div className="main-content--single-section">
                            <div className="pollen-cv-template--single-section--header">
                                <h3 className="pollen-cv-template--single-section--heading">Experience</h3>
                                <div className="pollen-cv-template--single-section--header-underline"></div>
                            </div>
                            <div className="main-content--single-section-content job-list">
                                <WorkExperienceTimeline employments={data.employments} />
                            </div>
                        </div>
                    }

                    {
                        data.educations && data.educations.length > 0 &&
                        <div className="main-content--single-section">
                            <div className="pollen-cv-template--single-section--header">
                                <h3 className="pollen-cv-template--single-section--heading">Education</h3>
                                <div className="pollen-cv-template--single-section--header-underline"></div>
                            </div>
                            <div className="main-content--single-section-content job-list">
                                <EducationTimeLine educations={data.educations || []} />
                            </div>
                        </div>
                    }




                </div>
            </div>
        </div>
    )
}
