import React from 'react'
import "./CharcoalTemplate.scss";
import { MdEmail, MdPhone } from 'react-icons/md';
import { FaFacebook, FaGithub, FaGlobe, FaLinkedin, FaMapMarked, FaTwitter } from 'react-icons/fa';
import { BsStarFill, BsStarHalf, BsStar } from 'react-icons/bs';
import { IoMdSchool } from 'react-icons/io';
import { ImOffice } from 'react-icons/im';
import moment from 'moment';
import { Timeline, TimelineItem, TimelineSeparator, TimelineConnector, TimelineContent, TimelineDot, TimelineOppositeContent } from '@material-ui/lab';

const isMobile = window.innerWidth <= 768;

const skillLabels = {
    0.5: 'Useless',
    1: 'Useless+',
    1.5: 'Poor',
    2: 'Poor+',
    2.5: 'Ok',
    3: 'Ok+',
    3.5: 'Good',
    4: 'Good+',
    4.5: 'Excellent',
    5: 'Excellent+',
};

const langLevels = {
    'basic': 25,
    'conversational': 50,
    'fluent': 75,
    'native': 100
}



export default function CharcoalTemplate(props) {
    const data = props.data || {};

    const formatDate = (d) => {
        return moment(d).format('MMM, yyyy');
    }


    const showSocialAccounts = () => {
        if (data && data.socialAccounts) {
            return data.socialAccounts.github || data.socialAccounts.facebook || data.socialAccounts.linkedIn || data.socialAccounts.twitter || data.socialAccounts.personalSite
        }

        return false
    }

    const getAddress = () => {
        return (`${data.intro.country ? data.intro.country.name : ''}, ${data.intro.city ? data.intro.city : ''}, ${data.intro.state ? data.intro.state : ''}, ${data.intro.zip ? data.intro.zip : ''}`).trim().replace(/^,|,$/g, '').trim();
    }



    const getStars = (stars) => {
        let stars_ = [];
        for (let i = 1; i <= stars; i++) {
            stars_.push(<BsStarFill size={isMobile ? 7.5 : 15} />);
        }
        let remainders = 5 - stars;
        if (remainders.toString().indexOf(".5") >= 0) {
            stars_.push(<BsStarHalf size={isMobile ? 7.5 : 15} />)
            remainders = remainders.toString().substr(0, remainders.toString().indexOf("."));
        }

        for (let i = 1; i <= remainders; i++) {
            stars_.push(<BsStar size={isMobile ? 7.5 : 15} />)
        }
        return stars_;
    }

    const WorkExperienceTimeline = ({ employments }) => {
        return (
            <React.Fragment>
                <Timeline className="obcv-t">
                    {
                        (employments || []).map(emp =>
                            <TimelineItem>
                                <TimelineSeparator>
                                    <TimelineDot />
                                    <TimelineConnector />
                                </TimelineSeparator>
                                <TimelineContent className="obcv-t-content-wrapper-r">
                                    <div className="obcv-t-l-w">
                                        <div className="obcv-t-l">
                                            <div className="obcv-t-l-c">
                                                {emp.jobTitle || ''}

                                            </div>
                                            <div className="obcv-t-l-d">
                                                {`${formatDate(emp.startDate)} - ${emp.currentEmployment ? 'Present' : formatDate(emp.endDate)}`}
                                            </div>
                                        </div>
                                        <div className="obcv-t-r">
                                            <div className="obcv-t-r-t">
                                                {emp.employer || ''}
                                            </div>
                                            <div className="obcv-t-r-c">
                                                {emp.description || ''}
                                            </div>
                                        </div>
                                    </div>
                                </TimelineContent>
                            </TimelineItem>
                        )
                    }
                </Timeline>
            </React.Fragment>
        )
    }


    const EducationTimeline = ({ educations }) => {
        return (
            <React.Fragment>
                <Timeline className="obcv-t">
                    {
                        (educations || []).map(ed =>
                            <TimelineItem>
                                <TimelineSeparator>
                                    <TimelineDot />
                                    <TimelineConnector />
                                </TimelineSeparator>
                                <TimelineContent className="obcv-t-content-wrapper-r">
                                    <div className="obcv-t-l-w">
                                        <div className="obcv-t-l">
                                            <div className="obcv-t-l-c">
                                                {ed.degree || ''}

                                            </div>
                                            <div className="obcv-t-l-d">
                                                {`${formatDate(ed.startDate)} - ${ed.currentSchool ? 'Present' : formatDate(ed.endDate)}`}
                                            </div>
                                        </div>
                                        <div className="obcv-t-r">
                                            <div className="obcv-t-r-t">
                                                {ed.schoolName || ''}
                                            </div>
                                            <div className="obcv-t-r-c">
                                                {ed.details || ''}
                                            </div>
                                        </div>
                                    </div>
                                </TimelineContent>
                            </TimelineItem>
                        )
                    }
                </Timeline>
            </React.Fragment>
        )
    }

    const AchievementTimeline = ({ achievements }) => {
        return (
            <React.Fragment>
                <Timeline className="obcv-t">
                    {

                        (achievements || []).map((ec, i) => {
                            return i % 2 === 0 ?
                                <TimelineItem>
                                    <TimelineSeparator>
                                        <TimelineDot />
                                        <TimelineConnector />
                                    </TimelineSeparator>
                                    <TimelineContent className="obcv-t-content-wrapper-r">
                                        <div className="charcoal-achievements-holder-single-row">
                                            <div className="obcv-t-l-w">
                                                <div className="obcv-t-l">
                                                    <div className="obcv-t-l-c">
                                                        {achievements[i].title || ''}
                                                    </div>
                                                </div>
                                                <div className="obcv-t-r">
                                                    <div className="obcv-t-r-t">
                                                        {achievements[i].company || ''}
                                                    </div>
                                                    <div className="obcv-t-r-c">
                                                        {achievements[i].details || ''}
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                achievements[i + 1] ?
                                                    <div className="obcv-t-l-w">
                                                        <div className="obcv-t-l">
                                                            <div className="obcv-t-l-c">
                                                                {achievements[i + 1].title || ''}
                                                            </div>
                                                        </div>
                                                        <div className="obcv-t-r">
                                                            <div className="obcv-t-r-t">
                                                                {achievements[i + 1].company || ''}
                                                            </div>
                                                            <div className="obcv-t-r-c">
                                                                {achievements[i + 1].details || ''}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    : null
                                            }
                                        </div>
                                    </TimelineContent>
                                </TimelineItem>

                                : null;
                        }

                        )
                    }
                </Timeline>
            </React.Fragment>
        )
    }



    return (
        <div id="charcoal-template-cv" ref={props.ref}>



            {
                /*  <div className="charcoal-cv-template--header--img">
                    {
                        data.intro && data.intro.picture && data.intro.picture.length > 3 &&
                        <div class="hexagon hexagon2">
                            <div class="hexagon-in1">
                                <div style={{ backgroundImage: `url(${data.intro.picture})` }} class="hexagon-in2"></div>
                            </div>
                        </div>
                    }
                </div>*/
            }

            {/* </div> */}





            <div className="content-wrapper" >


                <div className="main-content" >



                    <div className="main-content-wrapper">

                        <div className="charcoal-header-name">
                            <h1>{data.intro && data.intro.firstName ? data.intro.firstName : ''} {data.intro && data.intro.lastName ? data.intro.lastName : ''}</h1>
                            <div>
                                {data.intro.profession ? data.intro.profession.split(" ").map(e => {
                                    return <span>{e} </span>
                                }) : null}
                            </div>
                        </div>

                        {
                            data.intro && data.intro.objective && data.intro.objective.length &&

                            <div className="mt-2">
                                <div className="main-content--single-section-content">
                                    {
                                        data.intro.objective
                                    }
                                </div>
                            </div>

                        }



                        {
                            data.employments && data.employments.length > 0 &&

                            <div className="main-content--single-section">
                                <h1 className="main-content--single-section-heading">
                                    <span>Work Experience</span>

                                </h1>
                                <div className="main-content--single-section-content job-list">

                                    <WorkExperienceTimeline employments={data.employments} />
                                </div>

                                {console.log("ok", data.achievements)}



                            </div>



                        }


                        {
                            data.educations && data.educations.length > 0 &&
                            <div className="main-content--single-section">
                                <h1 className="main-content--single-section-heading">
                                    <span>Education</span>

                                </h1>
                                <div className="main-content--single-section-content education-list">
                                    {/* {
                                        data.educations.map(ed =>
                                            <div className="main-content--single-section-content-single-education">

                                                <div className="main-content--single-section-content-single-education-title">{ed.degree || ''}</div>
                                                <div className="main-content--single-section-content-single-education-company">{`${ed.schoolName} |` || ''} {`${formatDate(ed.startDate)} - ${ed.currentSchool ? 'Present' : formatDate(ed.endDate)}`}</div>
                                                <div className="main-content--single-section-content-single-education-description">
                                                    {ed.details || ''}
                                                </div>
                                            </div>
                                        )
                                    } */}

                                    <EducationTimeline educations={data.educations || []} />
                                </div>
                            </div>
                        }

                        {
                            data.achievements && data.achievements.length &&
                            <div className="main-content--single-section">
                                <h1 className="main-content--single-section-heading">
                                    <span>Achievements</span>

                                </h1>
                                <div className="main-content--single-section-content job-list">
                                    <AchievementTimeline achievements={data.achievements} />
                                </div>
                            </div>
                        }

                    </div>

                </div>


                <div className="right-sidebar">

                    {/* <div className="charcoal-cv-template--photo">
                        
                    </div> */}

                    <div className="charcoal-cv-template--single-section">
                        <div className="charcoal-cv-template--single-section--header">
                            <h1 className="charcoal-cv-template--single-section--heading">Contact</h1>
                        </div>
                        <div className="charcoal-cv-template--single-section--content">
                            <div className="charcoal-cv-template--single-section--icon-list">
                                {
                                    data.intro && data.intro.phone &&
                                    <div className="charcoal-cv-template--single-section--icon-list-single">
                                        <span className="charcoal-cv-template--single-section--icon-list-single-icon">
                                            <MdPhone size={isMobile ? 10 : 20} />
                                        </span>
                                        <span className="charcoal-cv-template--single-section--icon-list-single-content">{data.intro.phone}</span>
                                    </div>
                                }

                                {
                                    data.intro && data.intro.email &&
                                    <div className="charcoal-cv-template--single-section--icon-list-single">
                                        <span className="charcoal-cv-template--single-section--icon-list-single-icon">
                                            <MdEmail size={isMobile ? 10 : 20} />
                                        </span>
                                        <span className="charcoal-cv-template--single-section--icon-list-single-content">{data.intro.email}</span>
                                    </div>
                                }

                                {
                                    data.intro && (data.intro.country || data.intro.city || data.intro.state) &&
                                    <div className="charcoal-cv-template--single-section--icon-list-single">
                                        <span className="charcoal-cv-template--single-section--icon-list-single-icon">
                                            <FaMapMarked size={isMobile ? 10 : 20} />
                                        </span>
                                        <span className="charcoal-cv-template--single-section--icon-list-single-content">{getAddress()}</span>
                                    </div>
                                }
                            </div>

                        </div>
                    </div>

                    {
                        showSocialAccounts() &&
                        <>
                            <div className="charcoal-cv-template--single-section">
                                <div className="charcoal-cv-template--single-section--header">
                                    <h1 className="charcoal-cv-template--single-section--heading">Social</h1>

                                </div>
                                <div className="charcoal-cv-template--single-section--content">
                                    <div className="charcoal-cv-template--single-section--icon-list">
                                        {
                                            data.socialAccounts.facebook &&
                                            <div className="charcoal-cv-template--single-section--icon-list-single">
                                                <span className="charcoal-cv-template--single-section--icon-list-single-icon">
                                                    <FaFacebook size={isMobile ? 10 : 20} />
                                                </span>
                                                <span className="charcoal-cv-template--single-section--icon-list-single-content">{data.socialAccounts.facebook}</span>
                                            </div>
                                        }

                                        {
                                            data.socialAccounts.twitter &&
                                            <div className="charcoal-cv-template--single-section--icon-list-single">
                                                <span className="charcoal-cv-template--single-section--icon-list-single-icon">
                                                    <FaTwitter size={isMobile ? 10 : 20} />
                                                </span>
                                                <span className="charcoal-cv-template--single-section--icon-list-single-content">{data.socialAccounts.twitter}</span>
                                            </div>
                                        }

                                        {
                                            data.socialAccounts.linkedIn &&
                                            <div className="charcoal-cv-template--single-section--icon-list-single">
                                                <span className="charcoal-cv-template--single-section--icon-list-single-icon">
                                                    <FaLinkedin size={isMobile ? 10 : 20} />
                                                </span>
                                                <span className="charcoal-cv-template--single-section--icon-list-single-content">{data.socialAccounts.linkedIn}</span>
                                            </div>
                                        }

                                        {
                                            data.socialAccounts.github &&
                                            <div className="charcoal-cv-template--single-section--icon-list-single">
                                                <span className="charcoal-cv-template--single-section--icon-list-single-icon">
                                                    <FaGithub size={isMobile ? 10 : 20} />
                                                </span>
                                                <span className="charcoal-cv-template--single-section--icon-list-single-content">{data.socialAccounts.github}</span>
                                            </div>
                                        }

                                        {
                                            data.socialAccounts.personalSite &&
                                            <div className="charcoal-cv-template--single-section--icon-list-single">
                                                <span className="charcoal-cv-template--single-section--icon-list-single-icon">
                                                    <FaGlobe size={isMobile ? 10 : 20} />
                                                </span>
                                                <span className="charcoal-cv-template--single-section--icon-list-single-content"><a href={data.socialAccounts.personalSite}>{data.socialAccounts.personalSite.replace(/https:\/\//ig, '').replace(/http:\/\//ig, '')}</a></span>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </>
                    }

                    {
                        data.skills && data.skills.length > 0 &&
                        <>
                            <div className="charcoal-cv-template--single-section">
                                <div className="charcoal-cv-template--single-section--header">
                                    <h1 className="charcoal-cv-template--single-section--heading">Skills</h1>
                                </div>
                                <div className="charcoal-cv-template--single-section--content">
                                    <div className="charcoal-cv-template--single-section--keyassets-list l-u">
                                        {
                                            data.skills.map(s =>
                                                <div className="charcoal-cv-template--single-section--skill-list-single">
                                                    <span className="charcoal-cv-template--single-section--skill-list-single-skill">
                                                        {s.title || ''}
                                                    </span>
                                                </div>
                                            )
                                        }

                                    </div>
                                </div>
                            </div>
                        </>
                    }


                    {
                        data.languages && data.languages.length > 0 &&
                        <>
                            <div className="charcoal-cv-template--single-section">
                                <div className="charcoal-cv-template--single-section--header">
                                    <h1 className="charcoal-cv-template--single-section--heading">Languages</h1>

                                </div>
                                <div className="charcoal-cv-template--single-section--content">
                                    <div className="charcoal-cv-template--single-section--language-list">
                                        {
                                            data.languages.map(lan =>
                                                <div className="charcoal-cv-template--single-section--language-list-single">
                                                    <span className="charcoal-cv-template--single-section--language-list-single-content">
                                                        {lan.title || ''}
                                                    </span>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </>
                    }

                    {
                        data.keyAssets && data.keyAssets.length > 0 &&
                        <>
                            <div className="charcoal-cv-template--single-section">
                                <div className="charcoal-cv-template--single-section--header">
                                    <h1 className="charcoal-cv-template--single-section--heading">Key Assets</h1>
                                    <div className="charcoal-cv-template--single-section--header-underline"></div>
                                </div>
                                <div className="charcoal-cv-template--single-section--content">
                                    <div className="charcoal-cv-template--single-section--keyassets-list l-u">
                                        {
                                            data.keyAssets.map(ka =>
                                                <div className="charcoal-cv-template--single-section--skill-list-single">
                                                    <span className="charcoal-cv-template--single-section--skill-list-single-skill">
                                                        {ka.title || ''}
                                                    </span>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                </div>

            </div>
        </div>
    )
}
