import React from 'react'
import "./PaletteTemplate.scss";
import { MdEmail, MdPhone } from 'react-icons/md';
import { FaFacebook, FaGithub, FaGlobe, FaLinkedin, FaMapMarked, FaTwitter, FaGlobeAsia } from 'react-icons/fa';
import { BsStarFill, BsStarHalf, BsStar, BsPeopleFill } from 'react-icons/bs';
import { IoMdSchool } from 'react-icons/io';
import { ImOffice } from 'react-icons/im';
import { HiBadgeCheck } from 'react-icons/hi';
import { GoChecklist, GoBriefcase } from 'react-icons/go';
import { Timeline, TimelineItem, TimelineSeparator, TimelineConnector, TimelineContent, TimelineDot, TimelineOppositeContent } from '@material-ui/lab';
import moment from 'moment';

const isMobile = window.innerWidth <= 768;

const skillLabels = {
    0.5: 'Useless',
    1: 'Useless+',
    1.5: 'Poor',
    2: 'Poor+',
    2.5: 'Ok',
    3: 'Ok+',
    3.5: 'Good',
    4: 'Good+',
    4.5: 'Excellent',
    5: 'Excellent+',
};

const langLevels = {
    'basic': 25,
    'conversational': 50,
    'fluent': 75,
    'native': 100
}


const formatDate = (d) => {
    return moment(d).format('MMM, yyyy');
}

const EducationTimeline = ({ educations }) => {
    return (
        <React.Fragment>
            <Timeline className="obcv-t">
                {
                    (educations || []).map(ed =>
                        <TimelineItem>
                            <TimelineOppositeContent className="obcv-t-content-wrapper-l">
                                <div className="obcv-t-l">
                                    <div className="obcv-t-l-d">
                                        {`${formatDate(ed.startDate)} - ${ed.currentEmployment ? 'Present' : formatDate(ed.endDate)}`}
                                    </div>
                                    <div className="obcv-t-l-c">
                                        {ed.schoolName || ''}
                                    </div>
                                </div>
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineDot />
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent className="obcv-t-content-wrapper-r">
                                <div className="obcv-t-r">
                                    <div className="obcv-t-r-t">
                                        {ed.degree || ''}
                                    </div>
                                    <div className="obcv-t-r-c">
                                        {ed.details || ''}
                                    </div>
                                </div>
                            </TimelineContent>
                        </TimelineItem>
                    )
                }
            </Timeline>
        </React.Fragment>
    )
}


const WorkExperienceTimeline = ({ employments }) => {
    return (

        <React.Fragment>

            <Timeline className="obcv-t">
                {
                    (employments || []).map(emp =>
                        <TimelineItem>
                            <TimelineOppositeContent className="obcv-t-content-wrapper-l">
                                <div className="obcv-t-l">
                                    <div className="obcv-t-l-d">
                                        {`${formatDate(emp.startDate)} - ${emp.currentEmployment ? 'Present' : formatDate(emp.endDate)}`}
                                    </div>
                                    <div className="obcv-t-l-c">
                                        {emp.employer || ''}
                                    </div>
                                </div>
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineDot />
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent className="obcv-t-content-wrapper-r">
                                <div className="obcv-t-r">
                                    <div className="obcv-t-r-t">
                                        {emp.jobTitle || ''}
                                    </div>
                                    <div className="obcv-t-r-c">
                                        {emp.description || ''}
                                    </div>
                                </div>
                            </TimelineContent>
                        </TimelineItem>
                    )
                }
            </Timeline>

        </React.Fragment>
    )
}




export default function PaletteTemplate(props) {
    const data = props.data || {};


    const AlphabetIcon = ({ content, style }) => {
        return <div style={style} className="pal-alp-icon-h">
            <span>{content}</span>
        </div>
    }

    React.useEffect(() => {
        // make sure no overlapping of sections occur
        let elems = document.querySelectorAll(".section-heading-pal");
        Array.from(elems || []).map(e => {
            e.classList.remove("margin-of-overlap");
            return e;
        }).map(e => {
            console.log(e);
            let elemMatching = Array.from(elems || []).find(e_ => {
                console.log(e, e_);
                console.log(e_.getBoundingClientRect().top, e.getBoundingClientRect().top, Math.abs(e_.getBoundingClientRect().top - e.getBoundingClientRect().top));
                return e_ !== e && (Math.abs(e_.getBoundingClientRect().top - e.getBoundingClientRect().top) <= 100)
            });
            if (elemMatching) {
                console.log("elem found");
                let downElement = Math.max(elemMatching.getBoundingClientRect().top, e.getBoundingClientRect().top);
                downElement = [elemMatching, e].find(e => e.getBoundingClientRect().top === downElement);
                downElement.classList.add("margin-of-overlap");
            }
        })
    }, []);

    const anySocialAccount = () => data.socialAccounts?.facebook || data.socialAccounts?.twitter || data.socialAccounts?.github || data.socialAccounts?.linkedIn;


    const showSocialAccounts = () => {
        if (data && data.socialAccounts) {
            return data.socialAccounts.github || data.socialAccounts.facebook || data.socialAccounts.linkedIn || data.socialAccounts.twitter || data.socialAccounts.personalSite
        }

        return false
    }

    const getAddress = () => {
        return (`${data.intro.country ? data.intro.country.name : ''}, ${data.intro.city ? data.intro.city : ''}, ${data.intro.state ? data.intro.state : ''}, ${data.intro.zip ? data.intro.zip : ''}`).trim().replace(/^,|,$/g, '').trim();
    }



    const getStars = (stars) => {
        let stars_ = [];
        for (let i = 1; i <= stars; i++) {
            stars_.push(<BsStarFill size={isMobile ? 7.5 : 15} />);
        }
        let remainders = 5 - stars;
        if (remainders.toString().indexOf(".5") >= 0) {
            stars_.push(<BsStarHalf size={isMobile ? 7.5 : 15} />)
            remainders = remainders.toString().substr(0, remainders.toString().indexOf("."));
        }
        return stars_;
    }

    return (
        <div id="Palette-template-cv" ref={props.ref}>

            <div className="header-top-margin"></div>
            <div className="header-banner">

                {/* <div className="header-banner-img-h">
                    <img src="" alt="" />
                </div> */}

                {/* <div className="header-banner-l">
                    <div className="header-banner-l-t"></div>
                    <div className="header-banner-l-b"></div>
                </div> */}
                <div className="header-banner-r">
                    <div className="header-banner-r-name-h">
                        {data.intro?.firstName && <h1>{data.intro.firstName}</h1>}
                        {data.intro?.lastName && <h1>{data.intro.lastName}</h1>}

                    </div>
                    <div className="header-banner-pos-h">
                        {data.intro?.profession && <h3>{data.intro.profession}</h3>}

                    </div>
                </div>
            </div>



            <Timeline className="palette-main-timeline ">
                <TimelineItem>

                    {
                        (anySocialAccount() || (data.intro && data.intro.objective && data.intro.objective.length >= 2)) &&
                        <TimelineOppositeContent>
                            <div className="palette-single-item-main-timeline tml-l">
                                {
                                    data.intro && data.intro.objective &&
                                    <div className="section-heading-pal lefty">

                                        <h3>Objective</h3>
                                        <div className="section-heading-pal-bar lefty">
                                            <span className="section-heading-pal-bar-handle">
                                                <span className="section-heading-pal-bar-handle-in"></span>
                                            </span>
                                        </div>
                                    </div>
                                }

                                <div className="section-content-pal">
                                    {
                                        data.intro && data.intro.objective && <p>
                                            {data.intro.objective}
                                        </p>
                                    }

                                    {
                                        anySocialAccount() &&
                                        <div className="pallete-social-acc-holder">
                                            {
                                                data.socialAccounts.facebook &&
                                                <div className="Palette-cv-template--single-section--icon-list-single">
                                                    <span className="Palette-cv-template--single-section--icon-list-single-icon">
                                                        <FaFacebook size={isMobile ? 15 : 30} />
                                                    </span>
                                                    <span className="Palette-cv-template--single-section--icon-list-single-content">{data.socialAccounts.facebook}</span>
                                                </div>
                                            }

                                            {
                                                data.socialAccounts.twitter &&
                                                <div className="Palette-cv-template--single-section--icon-list-single">
                                                    <span className="Palette-cv-template--single-section--icon-list-single-icon">
                                                        <FaTwitter size={isMobile ? 15 : 30} />
                                                    </span>
                                                    <span className="Palette-cv-template--single-section--icon-list-single-content">{data.socialAccounts.twitter}</span>
                                                </div>
                                            }

                                            {
                                                data.socialAccounts.linkedIn &&
                                                <div className="Palette-cv-template--single-section--icon-list-single">
                                                    <span className="Palette-cv-template--single-section--icon-list-single-icon">
                                                        <FaLinkedin size={isMobile ? 15 : 30} />
                                                    </span>
                                                    <span className="Palette-cv-template--single-section--icon-list-single-content">{data.socialAccounts.linkedIn}</span>
                                                </div>
                                            }

                                            {
                                                data.socialAccounts.github &&
                                                <div className="Palette-cv-template--single-section--icon-list-single">
                                                    <span className="Palette-cv-template--single-section--icon-list-single-icon">
                                                        <FaGithub size={isMobile ? 15 : 30} />
                                                    </span>
                                                    <span className="Palette-cv-template--single-section--icon-list-single-content">{data.socialAccounts.github}</span>
                                                </div>
                                            }

                                        </div>
                                    }

                                </div>
                            </div>

                            {
                                data.languages && data.languages.length > 0 &&
                                <div className="palette-single-item-main-timeline tml-l">
                                    <div className="section-heading-pal lefty">
                                        <h3>Languages</h3>
                                        <div className="section-heading-pal-bar lefty">
                                            <span className="section-heading-pal-bar-handle">
                                                <span className="section-heading-pal-bar-handle-in"></span>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="section-content-pal">
                                        {
                                            data.languages.map(lan =>
                                                <div className="Palette-cv-template--single-section--language-list-single">
                                                    <span className="Palette-cv-template--single-section--language-list-single-content">
                                                        {lan.title || ''}
                                                    </span>
                                                    <span>
                                                        <progress max={100} value={langLevels[lan.level || 'fluent']}></progress>
                                                    </span>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            }

                            {
                                data.keyAssets && data.keyAssets.length > 0 &&
                                <div className="palette-single-item-main-timeline tml-l">
                                    <div className="section-heading-pal lefty">
                                        <h3>Key Assets</h3>
                                        <div className="section-heading-pal-bar lefty">
                                            <span className="section-heading-pal-bar-handle">
                                                <span className="section-heading-pal-bar-handle-in"></span>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="section-content-pal">
                                        <div className="Palette-cv-template--single-section--keyassets-list">
                                            {
                                                data.keyAssets.map(ka =>
                                                    <div className="Palette-cv-template--single-section--skill-list-single">
                                                        <span className="Palette-cv-template--single-section--skill-list-single-skill">
                                                            {ka.title || ''}
                                                        </span>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                            }

                            {
                                data.educations && data.educations.length > 0 &&
                                <div className="palette-single-item-main-timeline tml-l">
                                    <div className="section-heading-pal lefty">
                                        <h3>Education</h3>
                                        <div className="section-heading-pal-bar lefty">
                                            <span className="section-heading-pal-bar-handle">
                                                <span className="section-heading-pal-bar-handle-in"></span>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="section-content-pal">
                                        <div className="main-content--single-section-content job-list">
                                            {
                                                (data.educations || []).map(edu => <div className="single-education-entry-palette">
                                                    <div className="s-ee-p-date">
                                                        {`${formatDate(edu.startDate)} - ${edu.currentSchool ? 'Present' : formatDate(edu.endDate)}`}
                                                    </div>
                                                    <div className="s-ee-p-c-and-d">
                                                        <span>{edu.schoolName || ''}</span>
                                                        <span><strong>{edu.degree ? ' / ' + edu.degree : ''}</strong></span>
                                                    </div>
                                                    <div className="s-ee-p-det">
                                                        <p>{edu.details || ''}</p>
                                                    </div>
                                                </div>)
                                            }
                                        </div>
                                    </div>
                                </div>
                            }
                        </TimelineOppositeContent>
                    }

                    <TimelineSeparator>
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                        <div className="mt-5">
                            {
                                data.intro && data.intro.phone &&
                                <div className="Palette-cv-template--single-contact">
                                    <AlphabetIcon style={{ backgroundColor: '#1bb3b0' }} content="C" />
                                    <span className="Palette-cv-template--single-section--icon-list-single-content">
                                        {data.intro.phone}
                                    </span>
                                </div>
                            }

                            {
                                data.intro && data.intro.email &&
                                <div className="Palette-cv-template--single-contact">
                                    <AlphabetIcon style={{ backgroundColor: '#fcc20c' }} content="E" />
                                    <span className="Palette-cv-template--single-section--icon-list-single-content">
                                        {data.intro.email}
                                    </span>

                                </div>
                            }

                            {
                                data.intro && (data.intro.country || data.intro.city || data.intro.state) &&
                                <div className="Palette-cv-template--single-contact">
                                    <AlphabetIcon style={{ backgroundColor: '#39a2d4' }} content="A" />
                                    <span className="Palette-cv-template--single-section--icon-list-single-content">
                                        {getAddress()}
                                    </span>
                                </div>
                            }

                            {
                                data.socialAccounts.personalSite &&
                                <div className="Palette-cv-template--single-contact">
                                    <AlphabetIcon style={{ backgroundColor: '#ec542b' }} content="A" />
                                    <span className="Palette-cv-template--single-section--icon-list-single-content">
                                        <a href={data.socialAccounts.personalSite}>{data.socialAccounts.personalSite.replace(/https:\/\//ig, '').replace(/http:\/\//ig, '')}</a>
                                    </span>
                                </div>
                            }
                        </div>
                        {
                            data.skills && data.skills.length > 0 &&
                            <div className="palette-single-item-main-timeline tml-l">
                                <div className="section-heading-pal righty">
                                    <div className="section-heading-pal-bar righty">
                                        <span className="section-heading-pal-bar-handle">
                                            <span className="section-heading-pal-bar-handle-in"></span>
                                        </span>
                                    </div>
                                    <h3>Skills</h3>
                                </div>
                                <div className="section-content-pal">
                                    <div className="Palette-cv-template--single-section--content">
                                        <div className="Palette-cv-template--single-section--skill-list">
                                            {
                                                data.skills.map(s =>
                                                    <div className="Palette-cv-template--single-section--skill-list-single">
                                                        <span className="Palette-cv-template--single-section--skill-list-single-skill">
                                                            {s.title || ''}
                                                        </span>
                                                        <span>
                                                            <progress max={5} min={0} step={0.5} value={s.grip || 0}></progress>
                                                        </span>
                                                    </div>
                                                )
                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>
                        }

                        {
                            data.employments && data.employments.length > 0 &&
                            <div className="palette-single-item-main-timeline tml-l">
                                <div className="section-heading-pal righty">
                                    <div className="section-heading-pal-bar righty">
                                        <span className="section-heading-pal-bar-handle">
                                            <span className="section-heading-pal-bar-handle-in"></span>
                                        </span>
                                    </div>
                                    <h3>Experience</h3>

                                </div>
                                <div className="section-content-pal">
                                    <div className="main-content--single-section-content job-list">
                                        {
                                            (data.employments || []).map(emp => <div className="single-education-entry-palette">
                                                <div className="s-ee-p-date">
                                                    {`${formatDate(emp.startDate)} - ${emp.currentEmployment ? 'Present' : formatDate(emp.endDate)}`}
                                                </div>
                                                <div className="s-ee-p-c-and-d">
                                                    <span>{emp.employer || ''}</span>
                                                    <span><strong>{emp.jobTitle ? ' / ' + emp.jobTitle : ''}</strong></span>
                                                </div>
                                                <div className="s-ee-p-det">
                                                    <p>{emp.description || ''}</p>
                                                </div>
                                            </div>)
                                        }
                                    </div>
                                </div>
                            </div>
                        }
                    </TimelineContent>

                </TimelineItem>





            </Timeline>


        </div>
    )
}
