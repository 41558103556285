import { authHeader, authToken } from '../helpers';
import CvTemplates from '../components/CvTemplates';
import { API_URL } from "../config/urls";

export const templateService = {
    getAll,
    jobTitleSuggestions
};

function getAll() {
    return CvTemplates;
}

function jobTitleSuggestions(q) {
    if (q && q.length) {
        const requestOptions = {
            method: 'GET',
            headers: authHeader()
        };

        return fetch(`${API_URL}/suggestions/job-title?q=${q}`, requestOptions).then(handleResponse);
    }
}


function handleResponse(response) {
    console.log(response)
    return response.text().then(text => {
        let data = text;
        try {
            data = data && JSON.parse(data);
        } catch (err) { }

        if (!response.ok) {
            const error = (data && data.msg) || data.errors || (typeof data === 'string' ? data : null) || response.responseText || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}