import React, { useEffect, useRef, useState } from 'react'
import { NavLink } from 'react-router-dom';
import './Navbar.scss';
import "bootstrap/js/src/collapse.js";
import "jquery/dist/jquery.min.js";
import 'bootstrap/dist/js/bootstrap.js';
import { IoIosMenu } from 'react-icons/io';
import { Button, Typography } from '@material-ui/core';
import { history } from '../../helpers/history';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '../../actions';
import { Avatar, makeStyles, Menu, MenuItem } from '@material-ui/core/';
import LogoImg from '../../img/branding/logo.svg';


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    purple: {
        color: "white",
        backgroundColor: 'orangered',
    },
}));


function Navbar(props) {

    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const [subMenuEl, setSubMenuEl] = useState(null);
    const dispatch = useDispatch();
    const navBarRef = useRef(null);
    const loggedIn = useSelector(state => state.authentication.loggedIn);
    const user = useSelector(state => state.authentication.user);


    const handleSubMenuHover = (event) => {
        setSubMenuEl(event.currentTarget);
    }

    const handleSubMenuHoverOut = (event) => {
        console.log(event);
        setSubMenuEl(null);
    }

    const handleProfileBadgeClick = event => {
        setAnchorEl(event.currentTarget);
    }

    const handleCloseProfileBadge = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        handleCloseProfileBadge();
        dispatch(userActions.logout());
    }


    const hanleLink = l => {
        history.push(l);
    }

    useEffect(() => {
        window.addEventListener('scroll', (e) => {
            let cl;
            if (window.outerWidth < 768) {
                cl = "fixed-bottom";
            } else {
                cl = "fixed-top";
            }

            if (navBarRef && navBarRef.current) {
                if (window.scrollY >= 60 && !navBarRef.current.classList.contains(cl)) {
                    navBarRef.current.classList.add(cl);
                } else if (window.scrollY < 60) {
                    navBarRef.current.classList.remove("fixed-top", "fixed-bottom");
                }
            }

        })
    }, []);



    return (

        <nav ref={navBarRef} className="navbar-main w-85 navbar navbar-expand-md navbar-toggleable-md justify-content-between bgTransparent">

            <NavLink className="navbar-brand mr-5" to="/">
                <img src={LogoImg} className="d-inline-block align-top" alt="" />
                <h4>BuildUrCV</h4>
            </NavLink>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
                <IoIosMenu />
            </button>
            <div className="collapse navbar-collapse justify-content-start" id="collapsibleNavbar">
                <ul className="navbar-nav ">
                    <Button aria-controls="resume-menu" aria-haspopup="true" className="m-0 p-0 men-li-btn" onTouchStart={handleSubMenuHover} onTouchStartCapture={handleSubMenuHover} onMouseEnter={handleSubMenuHover}>Resume</Button>
                    <Menu
                        id="resume-menu"
                        anchorEl={subMenuEl}
                        keepMounted
                        getContentAnchorEl={null}
                        open={Boolean(subMenuEl)}
                        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                        transformOrigin={{ vertical: "top", horizontal: "center" }}
                        onClose={handleSubMenuHoverOut}
                        onMouseLeave={handleSubMenuHoverOut}
                        MenuListProps={{ onMouseLeave: handleSubMenuHoverOut }}
                        disableScrollLock={true}
                    >
                        {
                            loggedIn &&
                            <MenuItem onClick={() => hanleLink('/cv/list')}><Typography className="ml-2 mr-2" variant={'body2'}>My Resumes</Typography></MenuItem>
                        }
                        <MenuItem onClick={() => hanleLink('/cv/new')}><Typography className="ml-2 mr-2" variant={'body2'}>Create</Typography></MenuItem>
                        <MenuItem onClick={() => hanleLink('/cv/samples')}><Typography className="ml-2 mr-2" variant={'body2'}>Samples</Typography></MenuItem>
                    </Menu>
                    <li className="nav-item"><NavLink activeClassName="active-nav" className="nav-link" to="/blog"> <Typography className="ml-2 mr-2" variant={'body2'}>Blog</Typography> </NavLink></li>
                    <li className="nav-item"><NavLink activeClassName="active-nav" className="nav-link" to="/about"> <Typography className="ml-2 mr-2" variant={'body2'}>About</Typography> </NavLink></li>
                    <li className="nav-item"><NavLink activeClassName="active-nav" className="nav-link" to="/contact"> <Typography className="ml-2 mr-2" variant={'body2'}>Contact Us</Typography> </NavLink></li>
                    <li className="nav-item"><NavLink activeClassName="active-nav" className="nav-link" to="/subscription"> <Typography className="ml-2 mr-2" variant={'body2'}>Subscriptions</Typography> </NavLink></li>
                </ul>
                <div className={'nav-right-men'}>
                    {
                        loggedIn
                            ?
                            <>
                                <Button className="m-0 p-0" onClick={handleProfileBadgeClick}><Avatar src={user.profileImage} className={classes.purple}>{(((user.name || '').split(" ")[0] || '').substr(0, 1)) + ((user.name || '').split(" ")[1] || '').substr(0, 1)}</Avatar> <Typography className="ml-2 mr-2" variant={'body2'}>{user.name}</Typography> </Button>
                                <Menu
                                    id="simple-menu"
                                    anchorEl={anchorEl}
                                    keepMounted
                                    getContentAnchorEl={null}
                                    open={Boolean(anchorEl)}
                                    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                                    transformOrigin={{ vertical: "top", horizontal: "center" }}
                                    onClose={handleCloseProfileBadge}
                                >
                                    {
                                        user && user.loginSource === 'local' &&
                                        <MenuItem onClick={() => hanleLink('/profile/updatePassword')}>Update Password</MenuItem>
                                    }

                                    <MenuItem onClick={() => hanleLink('/cv/list')}>List Cvs</MenuItem>
                                    {
                                        userActions.isAdmin(user)
                                            ?
                                            [
                                                <MenuItem disabled key={1}>Manage Blog</MenuItem>,
                                                <MenuItem key={2} onClick={() => hanleLink('/blog/ManagePost')}>All Posts</MenuItem>,
                                                <MenuItem key={3} onClick={() => hanleLink('/blog/ManagePost/add')}>Write Post</MenuItem>
                                            ]
                                            :
                                            null
                                    }
                                    <MenuItem onClick={handleLogout}>Logout</MenuItem>
                                </Menu>

                            </>

                            :
                            <>
                                <Button variant="outlined" onClick={() => history.push('/login')}>Login</Button>
                                <Button variant="contained" onClick={() => history.push('/signup')} color="primary" disableElevation>Sign up</Button>
                            </>
                    }

                </div>
            </div>

        </nav>
    )
}


export default Navbar;