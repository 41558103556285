import React from 'react'
import './Footer.scss';
import LogoImg from '../../img/branding/logo.svg';
import { ImFacebook, ImTwitter, ImLinkedin, ImPhone, ImWhatsapp } from 'react-icons/im';

export default function Footer() {
    return (
        <footer>
            <section className="footer--top-section ">
                <div className="footer--top-section--wrapper w-85 d-flex justify-content-between">
                    <p>&copy; 2022 Works Limited. All Rights Reserved.</p>
                    <div className="footer--top-section--menu-wrapper">
                        <ul className="footer--top-section--social--menu">
                            <li><a href="https://www.facebook.com/buildurcv/" target='_blank' rel="noreferrer"><ImFacebook /></a></li>
                            <li><a href="https://www.linkedin.com/company/buildurcv" target='_blank' rel="noreferrer"><ImLinkedin /></a></li>
                        </ul>
                        <ul className="footer--top-section--menu">
                            <li><a href="/privacy-policy">Privacy Policy</a></li>
                            <li><a href="/terms-and-conditions">Terms of Service</a></li>
                            <li><a href="/about">About</a></li>
                            <li><a href="/contact">Contact</a></li>
                        </ul>

                    </div>                    
                </div>
            </section>

            <section className="footer--bottom-section ">
                <div className="footer--bottom-section--wrapper w-85">
                    {/* <div>
                        <p className="footer--bottom-section--contact-holder">
                            <a href="tel:092 3343434334">
                                <ImPhone /> <span>092 3343434334</span>
                            </a>
                            <a href="https://wa.me/923343434334">
                                <ImWhatsapp /> <span>092 3343434334</span>
                            </a>
                        </p>

                    </div> */}
                    <div>
                        <a href="/" className="footer--bottom-section--wrapper--logo d-flex align-items-center align-content-center">
                            <img className="logo-bottom" src={LogoImg} />
                            <h3>BuildUrCV</h3>
                        </a>
                    </div>
                </div>
            </section>
        </footer>
    )
}
