import React from 'react';
import "./LazyLoader.scss";
import { CircularProgress } from "@material-ui/core";

export default function LazyLoader() {
    return (
        <div className="vh-100 w-100 d-flex justify-content-center align-items-center">
            <CircularProgress size={50} />
        </div>
    )
}
