import React from 'react'
import "./CoalTemplate.scss";
import { MdEmail, MdPhone } from 'react-icons/md';
import { FaFacebook, FaGithub, FaGlobe, FaLinkedin, FaMapMarked, FaTwitter } from 'react-icons/fa';
import { BsStarFill, BsStarHalf } from 'react-icons/bs';
import { IoMdSchool } from 'react-icons/io';
import { GoBriefcase } from 'react-icons/go';
import { Timeline, TimelineItem, TimelineSeparator, TimelineConnector, TimelineContent, TimelineDot, TimelineOppositeContent } from '@material-ui/lab';
import moment from 'moment';

const isMobile = window.innerWidth <= 768;

const skillLabels = {
    0.5: 'Useless',
    1: 'Useless+',
    1.5: 'Poor',
    2: 'Poor+',
    2.5: 'Ok',
    3: 'Ok+',
    3.5: 'Good',
    4: 'Good+',
    4.5: 'Excellent',
    5: 'Excellent+',
};

const langLevels = {
    'basic': 25,
    'conversational': 50,
    'fluent': 75,
    'native': 100
}

const formatDate = (d) => {
    return moment(d).format('MMM, yyyy');
}

const EducationTimeline = ({ educations }) => {
    return (
        <React.Fragment>
            <Timeline className="obcv-t">
                {
                    (educations || []).map(ed =>


                        <TimelineItem>
                            <TimelineSeparator>
                                <div className="obcv-timeline-connector">
                                    <span>  {formatDate(ed.startDate)}</span>
                                    <span>{ed.currentSchool ? 'Present' : formatDate(ed.endDate)}</span>
                                </div>
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent className="obcv-t-content-wrapper-r">
                                <div className="obcv-t-r">
                                    <div className="obcv-t-r-t">
                                        {ed.degree || ''}
                                    </div>
                                    <div className="obcv-t-l-c">
                                        {ed.schoolName || ''}
                                    </div>
                                    <div className="obcv-t-r-c">
                                        {ed.details || ''}
                                    </div>
                                </div>
                            </TimelineContent>
                        </TimelineItem>
                    )
                }
            </Timeline>
        </React.Fragment>
    )
}


const WorkExperienceTimeline = ({ employments }) => {
    return (
        <React.Fragment>
            <Timeline className="obcv-t">
                {
                    (employments || []).map(emp =>
                        <TimelineItem>
                            <TimelineSeparator>
                                <div className="obcv-timeline-connector">
                                    <span>  {formatDate(emp.startDate)}</span>
                                    <span>{emp.currentEmployment ? 'Present' : formatDate(emp.endDate)}</span>
                                </div>
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent className="obcv-t-content-wrapper-r">
                                <div className="obcv-t-r">
                                    <div className="obcv-t-r-t">
                                        {emp.jobTitle || ''}
                                    </div>
                                    <div className="obcv-t-l-c">
                                        {emp.employer || ''}
                                    </div>
                                    <div className="obcv-t-r-c">
                                        {emp.description || ''}
                                    </div>
                                </div>
                            </TimelineContent>
                        </TimelineItem>
                    )
                }
            </Timeline>
        </React.Fragment>
    )
}

export default function CoalTemplate(props) {
    const data = props.data || {};




    const showSocialAccounts = () => {
        if (data && data.socialAccounts) {
            return data.socialAccounts.github || data.socialAccounts.facebook || data.socialAccounts.linkedIn || data.socialAccounts.twitter || data.socialAccounts.personalSite
        }

        return false
    }

    const getAddress = () => {
        return (`${data.intro.country ? data.intro.country.name : ''}, ${data.intro.city ? data.intro.city : ''}, ${data.intro.state ? data.intro.state : ''}, ${data.intro.zip ? data.intro.zip : ''}`).trim().replace(/^,|,$/g, '').trim();
    }



    const getStars = (stars) => {
        let stars_ = [];
        for (let i = 1; i <= stars; i++) {
            stars_.push(<BsStarFill size={isMobile ? 7.5 : 15} />);
        }
        let remainders = 5 - stars;
        if (remainders.toString().indexOf(".5") >= 0) {
            stars_.push(<BsStarHalf size={isMobile ? 7.5 : 15} />)
            remainders = remainders.toString().substr(0, remainders.toString().indexOf("."));
        }
        return stars_;
    }

    return (
        <div id="coal-template-cv" ref={props.ref}>

            <div className="content-wrapper">
                <div className="left-sidebar">

                    <div className="coal-cv-template--header--image-wrapper">
                        <div className="coal-cv-template--header--image-container">
                            <div className="coal-cv-template--header--image-container-inner">

                            </div>
                        </div>
                    </div>


                   {/*  {
                        data.intro && data.intro.objective &&
                        <div className="coal-cv-template--single-section">
                            <div className="coal-cv-template--single-section--header">
                                <h1 className="coal-cv-template--single-section--heading">Objective</h1>
                            </div>
                            <div className="coal-cv-template--single-section--content">
                                {data.intro.objective}
                            </div>

                        </div>
                    } */}


                    <div className="coal-cv-template--single-section">
                        <div className="coal-cv-template--single-section--header">
                            <h1 className="coal-cv-template--single-section--heading">Contact</h1>
                        </div>
                        <div className="coal-cv-template--single-section--content">
                            <div className="coal-cv-template--single-section--icon-list">
                                {
                                    data.intro && data.intro.phone &&
                                    <div className="coal-cv-template--single-section--icon-list-single">
                                        <span className="coal-cv-template--single-section--icon-list-single-icon">
                                            <MdPhone size={isMobile ? 10 : 20} />
                                        </span>
                                        <span className="coal-cv-template--single-section--icon-list-single-content">{data.intro.phone}</span>
                                    </div>
                                }

                                {
                                    data.intro && data.intro.email &&
                                    <div className="coal-cv-template--single-section--icon-list-single">
                                        <span className="coal-cv-template--single-section--icon-list-single-icon">
                                            <MdEmail size={isMobile ? 10 : 20} />
                                        </span>
                                        <span className="coal-cv-template--single-section--icon-list-single-content">{data.intro.email}</span>
                                    </div>
                                }

                                {
                                    data.intro && (data.intro.country || data.intro.city || data.intro.state) &&
                                    <div className="coal-cv-template--single-section--icon-list-single">
                                        <span className="coal-cv-template--single-section--icon-list-single-icon">
                                            <FaMapMarked size={isMobile ? 10 : 20} />
                                        </span>
                                        <span className="coal-cv-template--single-section--icon-list-single-content">{getAddress()}</span>
                                    </div>
                                }


                                {
                                    data.socialAccounts.personalSite &&
                                    <div className="coal-cv-template--single-section--icon-list-single">
                                        <span className="coal-cv-template--single-section--icon-list-single-icon">
                                            <FaGlobe size={isMobile ? 10 : 20} />
                                        </span>
                                        <span className="coal-cv-template--single-section--icon-list-single-content"><a href={data.socialAccounts.personalSite}>{data.socialAccounts.personalSite.replace(/https:\/\//ig, '').replace(/http:\/\//ig, '')}</a></span>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>


                    {
                        showSocialAccounts() &&
                        <>

                            <div className="coal-cv-template--single-section">
                                <div className="coal-cv-template--single-section--header">
                                    <h1 className="coal-cv-template--single-section--heading">Social</h1>
                                </div>
                                <div className="coal-cv-template--single-section--content">
                                    <div className="coal-cv-template--single-section--icon-list">
                                        {
                                            data.socialAccounts.facebook &&
                                            <div className="coal-cv-template--single-section--icon-list-single">
                                                <span className="coal-cv-template--single-section--icon-list-single-icon">
                                                    <FaFacebook size={isMobile ? 10 : 20} />
                                                </span>
                                                <span className="coal-cv-template--single-section--icon-list-single-content">{data.socialAccounts.facebook}</span>
                                            </div>
                                        }

                                        {
                                            data.socialAccounts.twitter &&
                                            <div className="coal-cv-template--single-section--icon-list-single">
                                                <span className="coal-cv-template--single-section--icon-list-single-icon">
                                                    <FaTwitter size={isMobile ? 10 : 20} />
                                                </span>
                                                <span className="coal-cv-template--single-section--icon-list-single-content">{data.socialAccounts.twitter}</span>
                                            </div>
                                        }

                                        {
                                            data.socialAccounts.linkedIn &&
                                            <div className="coal-cv-template--single-section--icon-list-single">
                                                <span className="coal-cv-template--single-section--icon-list-single-icon">
                                                    <FaLinkedin size={isMobile ? 10 : 20} />
                                                </span>
                                                <span className="coal-cv-template--single-section--icon-list-single-content">{data.socialAccounts.linkedIn}</span>
                                            </div>
                                        }

                                        {
                                            data.socialAccounts.github &&
                                            <div className="coal-cv-template--single-section--icon-list-single">
                                                <span className="coal-cv-template--single-section--icon-list-single-icon">
                                                    <FaGithub size={isMobile ? 10 : 20} />
                                                </span>
                                                <span className="coal-cv-template--single-section--icon-list-single-content">{data.socialAccounts.github}</span>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>

                        </>
                    }




                    {
                        data.skills && data.skills.length > 0 &&
                        <>
                            <div className="coal-cv-template--single-section">
                                <div className="coal-cv-template--single-section--header">
                                    <h1 className="coal-cv-template--single-section--heading">Skills</h1>

                                </div>
                                <div className="coal-cv-template--single-section--content">
                                    <div className="coal-cv-template--single-section--skill-list">
                                        {
                                            data.skills.map(s =>
                                                <div className="coal-cv-template--single-section--skill-list-single">
                                                    <span className="coal-cv-template--single-section--skill-list-single-skill">
                                                        {s.title || ''}
                                                    </span>
                                                    <span className="coal-cv-template--single-section--skill-list-single-stars">{getStars(s.grip || 0)}</span>
                                                </div>
                                            )
                                        }

                                    </div>
                                </div>
                            </div>
                        </>
                    }


                    {
                        data.languages && data.languages.length > 0 &&
                        <>
                            <div className="coal-cv-template--single-section">
                                <div className="coal-cv-template--single-section--header">
                                    <h1 className="coal-cv-template--single-section--heading">Languages</h1>

                                </div>
                                <div className="coal-cv-template--single-section--content">
                                    <div className="coal-cv-template--single-section--language-list">
                                        {
                                            data.languages.map(lan =>
                                                <div className="coal-cv-template--single-section--language-list-single">
                                                    <div className="progress-circle-container">
                                                        <div className={`progress-circle progress-${langLevels[lan.level || 'conversational']}`}><span>{langLevels[lan.level || 'conversational']}</span></div>
                                                    </div>
                                                    <span className="coal-cv-template--single-section--language-list-single-content">
                                                        {lan.title || ''}
                                                    </span>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </>
                    }

                    {
                        data.keyAssets && data.keyAssets.length > 0 &&
                        <>
                            <div className="coal-cv-template--single-section">
                                <div className="coal-cv-template--single-section--header">
                                    <h1 className="coal-cv-template--single-section--heading">Key Assets</h1>

                                </div>
                                <div className="coal-cv-template--single-section--content">
                                    <div className="coal-cv-template--single-section--keyassets-list">
                                        {
                                            data.keyAssets.map(ka =>
                                                <div className="coal-cv-template--single-section--skill-list-single">
                                                    <span className="coal-cv-template--single-section--skill-list-single-skill">
                                                        {ka.title || ''}
                                                    </span>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                </div>

                <div className="main-content">

                    <div className="coal-cv-template--header">
                        <div className="coal-cv-template--header--name">
                            <h1>{data.intro && data.intro.firstName ? data.intro.firstName : null} {data.intro && data.intro.lastName ? data.intro.lastName : null}</h1>
                            <div>
                                {data.intro.profession ? data.intro.profession.split(" ").map(e => {
                                    return <span>{e} </span>
                                }) : null}
                            </div>
                        </div>
                    </div>

                    <div className="coal-cv-template--single-section--content--bottom-line"></div>

                    {
                        data.intro && data.intro.objective &&
                        <div className="coal-cv-template--single-section">
                            <div className="coal-cv-template--single-section--header">
                                <h1 className="coal-cv-template--single-section--heading">
                                    <span>Objective</span>
                                    <span className="coal-cv-template--single-section--heading-icon">
                                        <GoBriefcase size={isMobile ? 10 : 20} />
                                    </span>
                                </h1>
                            </div>
                             <div className="coal-cv-template--single-section--content" style={{color:"#7d7d85"}}>
                                {data.intro.objective}
                            </div>
                        </div>
                    }

                    {
                        data.employments && data.employments.length > 0 &&
                        <div className="coal-cv-template--single-section">
                            <div className="coal-cv-template--single-section--header">
                                <h1 className="coal-cv-template--single-section--heading">
                                    <span>Work Experience</span>
                                    <span className="coal-cv-template--single-section--heading-icon">
                                        <GoBriefcase size={isMobile ? 10 : 20} />
                                    </span>
                                </h1>
                            </div>
                            <div className="main-content--single-section-content job-list">
                                <WorkExperienceTimeline employments={data.employments} />

                            </div>
                        </div>
                    }

                    {
                        data.educations && data.educations.length > 0 &&
                        <div className="coal-cv-template--single-section">
                            <div className="coal-cv-template--single-section--header">
                                <h1 className="coal-cv-template--single-section--heading">
                                    <span>Education</span>
                                    <span className="coal-cv-template--single-section--heading-icon">
                                        <IoMdSchool size={isMobile ? 10 : 20} />
                                    </span>
                                </h1>

                            </div>
                            <div className="main-content--single-section-content job-list">
                                <EducationTimeline educations={data.educations} />

                            </div>
                        </div>
                    }


                </div>
            </div>
        </div>
    )
}
