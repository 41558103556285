import React from 'react'
import "./OrangeBreezeTemplate.scss";
import { MdEmail, MdPhone } from 'react-icons/md';
import { FaFacebook, FaGithub, FaGlobe, FaLinkedin, FaMapMarked, FaTwitter, FaGlobeAsia } from 'react-icons/fa';
import { BsStarFill, BsStarHalf, BsStar, BsPeopleFill } from 'react-icons/bs';
import { IoMdSchool } from 'react-icons/io';
import { ImOffice } from 'react-icons/im';
import { HiBadgeCheck } from 'react-icons/hi';
import { GoChecklist, GoBriefcase } from 'react-icons/go';
import { Timeline, TimelineItem, TimelineSeparator, TimelineConnector, TimelineContent, TimelineDot, TimelineOppositeContent } from '@material-ui/lab';
import moment from 'moment';

const isMobile = window.innerWidth <= 768;

const skillLabels = {
    0.5: 'Useless',
    1: 'Useless+',
    1.5: 'Poor',
    2: 'Poor+',
    2.5: 'Ok',
    3: 'Ok+',
    3.5: 'Good',
    4: 'Good+',
    4.5: 'Excellent',
    5: 'Excellent+',
};

const langLevels = {
    'basic': 25,
    'conversational': 50,
    'fluent': 75,
    'native': 100
}

const formatDate = (d) => {
    return moment(d).format('MMM, yyyy');
}

const EducationTimeline = ({ educations }) => {
    return (
        <React.Fragment>
            <Timeline className="obcv-t">
                {
                    (educations || []).map(ed =>
                        <TimelineItem>
                            <TimelineOppositeContent className="obcv-t-content-wrapper-l">
                                <div className="obcv-t-l">
                                    <div className="obcv-t-l-d">
                                        {`${formatDate(ed.startDate)} - ${ed.currentSchool ? 'Present' : formatDate(ed.endDate)}`}
                                    </div>
                                    <div className="obcv-t-l-c">
                                        {ed.schoolName || ''}
                                    </div>
                                </div>
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineDot />
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent className="obcv-t-content-wrapper-r">
                                <div className="obcv-t-r">
                                    <div className="obcv-t-r-t">
                                        {ed.degree || ''}
                                    </div>
                                    <div className="obcv-t-r-c">
                                        {ed.details || ''}
                                    </div>
                                </div>
                            </TimelineContent>
                        </TimelineItem>
                    )
                }
            </Timeline>
        </React.Fragment>
    )
}


const WorkExperienceTimeline = ({ employments }) => {
    return (
        <React.Fragment>
            <Timeline className="obcv-t">
                {
                    (employments || []).map(emp =>
                        <TimelineItem>
                            <TimelineOppositeContent className="obcv-t-content-wrapper-l">
                                <div className="obcv-t-l">
                                    <div className="obcv-t-l-d">
                                        {`${formatDate(emp.startDate)} - ${emp.currentEmployment ? 'Present' : formatDate(emp.endDate)}`}
                                    </div>
                                    <div className="obcv-t-l-c">
                                        {emp.employer || ''}
                                    </div>
                                </div>
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineDot />
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent className="obcv-t-content-wrapper-r">
                                <div className="obcv-t-r">
                                    <div className="obcv-t-r-t">
                                        {emp.jobTitle || ''}
                                    </div>
                                    <div className="obcv-t-r-c">
                                        {emp.description || ''}
                                    </div>
                                </div>
                            </TimelineContent>
                        </TimelineItem>
                    )
                }
            </Timeline>
        </React.Fragment>
    )
}

export default function OrangeBreezeTemplate(props) {
    const data = props.data || {};




    const showSocialAccounts = () => {
        if (data && data.socialAccounts) {
            return data.socialAccounts.github || data.socialAccounts.facebook || data.socialAccounts.linkedIn || data.socialAccounts.twitter || data.socialAccounts.personalSite
        }

        return false
    }

    const getAddress = () => {
        return (`${data.intro.country ? data.intro.country.name : ''}, ${data.intro.city ? data.intro.city : ''}, ${data.intro.state ? data.intro.state : ''}, ${data.intro.zip ? data.intro.zip : ''}`).trim().replace(/^,|,$/g, '').trim();
    }



    const getStars = (stars) => {
        let stars_ = [];
        for (let i = 1; i <= stars; i++) {
            stars_.push(<BsStarFill size={isMobile ? 7.5 : 15} />);
        }
        let remainders = 5 - stars;
        if (remainders.toString().indexOf(".5") >= 0) {
            stars_.push(<BsStarHalf size={isMobile ? 7.5 : 15} />)
            remainders = remainders.toString().substr(0, remainders.toString().indexOf("."));
        }
        return stars_;
    }

    return (
        <div id="orange-breeze-template-cv" ref={props.ref}>
            <div className="orange-breeze-cv-template--header">
                <div className="orange-breeze-cv-template--header--name">
                    {data.intro && data.intro.firstName ? <h1>{data.intro.firstName}</h1> : null}
                    {data.intro && data.intro.lastName ? <h1>{data.intro.lastName}</h1> : null}
                </div>

                <div className="orange-breeze-cv-template--header--contact">
                    {
                        data.intro && data.intro.phone &&
                        <div className="orange-breeze-cv-template--single-contact">
                            <span className="orange-breeze-cv-template--single-section--icon-list-single-content">
                                <span className="obcv-c-h">Phone</span>
                                <span className="obcv-c-v">{data.intro.phone}</span>
                            </span>
                            <span className="orange-breeze-cv-template--single-contact--icon">
                                <MdPhone size={isMobile ? 10 : 20} />
                            </span>
                        </div>
                    }

                    {
                        data.intro && data.intro.email &&
                        <div className="orange-breeze-cv-template--single-contact">
                            <span className="orange-breeze-cv-template--single-section--icon-list-single-content">
                                <span className="obcv-c-h">Email</span>
                                <span className="obcv-c-v">{data.intro.email}</span>
                            </span>
                            <span className="orange-breeze-cv-template--single-contact--icon">
                                <MdEmail size={isMobile ? 10 : 20} />
                            </span>
                        </div>
                    }

                    {
                        data.intro && (data.intro.country || data.intro.city || data.intro.state) &&
                        <div className="orange-breeze-cv-template--single-contact">
                            <span className="orange-breeze-cv-template--single-section--icon-list-single-content">
                                <span className="obcv-c-h">Address</span>
                                <span className="obcv-c-v">{getAddress()}</span>
                            </span>
                            <span className="orange-breeze-cv-template--single-contact--icon">
                                <FaMapMarked size={isMobile ? 10 : 20} />
                            </span>
                        </div>
                    }
                </div>

                {/* <div className="orange-breeze-cv-template--header--img">
                    {
                        data.intro && data.intro.picture && data.intro.picture.length > 3 &&
                        <div class="hexagon hexagon2">
                            <div class="hexagon-in1">
                                <div style={{ backgroundImage: `url(${data.intro.picture})` }} class="hexagon-in2"></div>
                            </div>
                        </div>
                    }
                </div> */}
            </div>
            <div className="obcv-profession">
                {data.intro.profession ? data.intro.profession.split(" ").map(e => {
                    return <span>{e} </span>
                }) : null}
            </div>

            <div className="obcv-hero">
                {/* <div className="obcv-hero-img">
                    <img src="" />
                </div> */}
                <div className="obcv-hero-content">
                    <h1 className="obcv-hero-content-title">Objective</h1>
                    <div className="obcv-hero-content-title-border"></div>
                    <div className="obcv-hero-content-text">{data.intro.objective}</div>
                </div>
            </div>

            <div className="content-wrapper">
                <div className="left-sidebar">
                    {
                        showSocialAccounts() &&
                        <>
                            <div className="orange-breeze-cv-template--single-section">
                                <div className="orange-breeze-cv-template--single-section--header">
                                    <h1 className="orange-breeze-cv-template--single-section--heading">Social</h1>
                                    <div className="orange-breeze-cv-template--single-section--header-icon">
                                        <BsPeopleFill size={isMobile ? 10 : 20} />
                                    </div>
                                </div>
                                <div className="orange-breeze-cv-template--single-section--content">
                                    <div className="orange-breeze-cv-template--single-section--icon-list">
                                        {
                                            data.socialAccounts.facebook &&
                                            <div className="orange-breeze-cv-template--single-section--icon-list-single">
                                                <span className="orange-breeze-cv-template--single-section--icon-list-single-icon">
                                                    <FaFacebook size={isMobile ? 10 : 20} />
                                                </span>
                                                <span className="orange-breeze-cv-template--single-section--icon-list-single-content">{data.socialAccounts.facebook}</span>
                                            </div>
                                        }

                                        {
                                            data.socialAccounts.twitter &&
                                            <div className="orange-breeze-cv-template--single-section--icon-list-single">
                                                <span className="orange-breeze-cv-template--single-section--icon-list-single-icon">
                                                    <FaTwitter size={isMobile ? 10 : 20} />
                                                </span>
                                                <span className="orange-breeze-cv-template--single-section--icon-list-single-content">{data.socialAccounts.twitter}</span>
                                            </div>
                                        }

                                        {
                                            data.socialAccounts.linkedIn &&
                                            <div className="orange-breeze-cv-template--single-section--icon-list-single">
                                                <span className="orange-breeze-cv-template--single-section--icon-list-single-icon">
                                                    <FaLinkedin size={isMobile ? 10 : 20} />
                                                </span>
                                                <span className="orange-breeze-cv-template--single-section--icon-list-single-content">{data.socialAccounts.linkedIn}</span>
                                            </div>
                                        }

                                        {
                                            data.socialAccounts.github &&
                                            <div className="orange-breeze-cv-template--single-section--icon-list-single">
                                                <span className="orange-breeze-cv-template--single-section--icon-list-single-icon">
                                                    <FaGithub size={isMobile ? 10 : 20} />
                                                </span>
                                                <span className="orange-breeze-cv-template--single-section--icon-list-single-content">{data.socialAccounts.github}</span>
                                            </div>
                                        }

                                        {
                                            data.socialAccounts.personalSite &&
                                            <div className="orange-breeze-cv-template--single-section--icon-list-single">
                                                <span className="orange-breeze-cv-template--single-section--icon-list-single-icon">
                                                    <FaGlobe size={isMobile ? 10 : 20} />
                                                </span>
                                                <span className="orange-breeze-cv-template--single-section--icon-list-single-content"><a href={data.socialAccounts.personalSite}>{data.socialAccounts.personalSite.replace(/https:\/\//ig, '').replace(/http:\/\//ig, '')}</a></span>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </>
                    }

                    {
                        data.skills && data.skills.length > 0 &&
                        <>
                            <div className="orange-breeze-cv-template--single-section">
                                <div className="orange-breeze-cv-template--single-section--header">
                                    <h1 className="orange-breeze-cv-template--single-section--heading">Skills</h1>
                                    <div className="orange-breeze-cv-template--single-section--header-icon">
                                        <HiBadgeCheck size={isMobile ? 10 : 20} />
                                    </div>
                                </div>
                                <div className="orange-breeze-cv-template--single-section--content">
                                    <div className="orange-breeze-cv-template--single-section--skill-list">
                                        {
                                            data.skills.map(s =>
                                                <div className="orange-breeze-cv-template--single-section--skill-list-single">
                                                    <span className="orange-breeze-cv-template--single-section--skill-list-single-skill">
                                                        {s.title || ''}
                                                    </span>
                                                    <span className="orange-breeze-cv-template--single-section--skill-list-single-stars">{getStars(s.grip || 0)}</span>
                                                </div>
                                            )
                                        }

                                    </div>
                                </div>
                            </div>
                        </>
                    }


                    {
                        data.languages && data.languages.length > 0 &&
                        <>
                            <div className="orange-breeze-cv-template--single-section">
                                <div className="orange-breeze-cv-template--single-section--header">
                                    <h1 className="orange-breeze-cv-template--single-section--heading">Languages</h1>
                                    <div className="orange-breeze-cv-template--single-section--header-icon">
                                        <FaGlobeAsia size={isMobile ? 10 : 20} />
                                    </div>
                                </div>
                                <div className="orange-breeze-cv-template--single-section--content">
                                    <div className="orange-breeze-cv-template--single-section--language-list">
                                        {
                                            data.languages.map(lan =>
                                                <div className="orange-breeze-cv-template--single-section--language-list-single">
                                                    <div className="progress-circle-container">
                                                        <div className={`progress-circle progress-${langLevels[lan.level || 'conversational']}`}><span>{langLevels[lan.level || 'conversational']}</span></div>
                                                    </div>
                                                    <span className="orange-breeze-cv-template--single-section--language-list-single-content">
                                                        {lan.title || ''}
                                                    </span>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </>
                    }

                    {
                        data.keyAssets && data.keyAssets.length > 0 &&
                        <>
                            <div className="orange-breeze-cv-template--single-section">
                                <div className="orange-breeze-cv-template--single-section--header">
                                    <h1 className="orange-breeze-cv-template--single-section--heading">Key Assets</h1>
                                    <div className="orange-breeze-cv-template--single-section--header-icon">
                                        <GoChecklist size={isMobile ? 10 : 20} />
                                    </div>
                                </div>
                                <div className="orange-breeze-cv-template--single-section--content">
                                    <div className="orange-breeze-cv-template--single-section--keyassets-list">
                                        {
                                            data.keyAssets.map(ka =>
                                                <div className="orange-breeze-cv-template--single-section--skill-list-single">
                                                    <span className="orange-breeze-cv-template--single-section--skill-list-single-skill">
                                                        {ka.title || ''}
                                                    </span>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                </div>

                <div className="main-content">

                    {
                        data.employments && data.employments.length > 0 &&
                        <div className="orange-breeze-cv-template--single-section">
                            <div className="orange-breeze-cv-template--single-section--header">
                                <h1 className="orange-breeze-cv-template--single-section--heading">Work Experience</h1>
                                <div className="orange-breeze-cv-template--single-section--header-icon">
                                    <GoBriefcase size={isMobile ? 10 : 20} />
                                </div>
                            </div>
                            <div className="main-content--single-section-content job-list">
                                <WorkExperienceTimeline employments={data.employments} />

                            </div>
                        </div>
                    }

                    {
                        data.educations && data.educations.length > 0 &&
                        <div className="orange-breeze-cv-template--single-section">
                            <div className="orange-breeze-cv-template--single-section--header">
                                <h1 className="orange-breeze-cv-template--single-section--heading">Education</h1>
                                <div className="orange-breeze-cv-template--single-section--header-icon">
                                    <IoMdSchool size={isMobile ? 10 : 20} />
                                </div>
                            </div>
                            <div className="main-content--single-section-content job-list">
                                <EducationTimeline educations={data.educations} />

                            </div>
                        </div>
                    }


                </div>
            </div>
        </div>
    )
}
