import React from 'react'
import "./KinoTemplate.scss";
import { MdEmail, MdPhone } from 'react-icons/md';
import { FaFacebook, FaGithub, FaGlobe, FaLinkedin, FaMapMarked, FaTwitter } from 'react-icons/fa';
import { BsStarFill, BsStarHalf, BsStar } from 'react-icons/bs';
import { IoMdSchool } from 'react-icons/io';
import { ImOffice } from 'react-icons/im';
import moment from 'moment';

const isMobile = window.innerWidth <= 768;

const skillLabels = {
    0.5: 'Useless',
    1: 'Useless+',
    1.5: 'Poor',
    2: 'Poor+',
    2.5: 'Ok',
    3: 'Ok+',
    3.5: 'Good',
    4: 'Good+',
    4.5: 'Excellent',
    5: 'Excellent+',
};

const langLevels = {
    'basic': 25,
    'conversational': 50,
    'fluent': 75,
    'native': 100
}

export default function KinoTemplate(props) {
    const data = props.data || {};

    const formatDate = (d) => {
        return moment(d).format('MMM, yyyy');
    }


    const showSocialAccounts = () => {
        if (data && data.socialAccounts) {
            return data.socialAccounts.github || data.socialAccounts.facebook || data.socialAccounts.linkedIn || data.socialAccounts.twitter || data.socialAccounts.personalSite
        }

        return false
    }

    const getAddress = () => {
        return (`${data.intro.country ? data.intro.country.name : ''}, ${data.intro.city ? data.intro.city : ''}, ${data.intro.state ? data.intro.state : ''}, ${data.intro.zip ? data.intro.zip : ''}`).trim().replace(/^,|,$/g, '').trim();
    }



    const getStars = (stars) => {
        let stars_ = [];
        for (let i = 1; i <= stars; i++) {
            stars_.push(<BsStarFill size={isMobile ? 7.5 : 15} />);
        }
        let remainders = 5 - stars;
        if (remainders.toString().indexOf(".5") >= 0) {
            stars_.push(<BsStarHalf size={isMobile ? 7.5 : 15} />)
            remainders = remainders.toString().substr(0, remainders.toString().indexOf("."));
        }

        for (let i = 1; i <= remainders; i++) {
            stars_.push(<BsStar size={isMobile ? 7.5 : 15} />)
        }
        return stars_;
    }

    return (
        <div id="kino-template-cv" ref={props.ref}>
            <div className="kino-cv-template--header">
                <div className="kcvt-h-l">
                    <div className="kino-cv-template--header--name">
                        {data.intro && data.intro.firstName ? <h1>{data.intro.firstName}</h1> : null}
                        {data.intro && data.intro.lastName ? <h1>{data.intro.lastName}</h1> : null}
                        <div>
                            {data.intro.profession ? data.intro.profession.split(" ").map(e => {
                                return <span>{e} </span>
                            }) : null}
                        </div>
                    </div>
                </div>
                <div className="kcvt-h-r">
                    {/* <div className="kcvt-h-r-dp-holder">
                        <div className="kcvt-h-r-dp"></div>
                    </div> */}
                    {
                        data.intro && data.intro.objective && data.intro.objective.length &&
                        <div className="kcvt-h-r-o-holder">
                            <div className="kcvt-h-r-o">
                                <h1 className="kcvt-h-r-o-holder-head">
                                    Objective
                                </h1>
                                <div className="kcvt-h-r-o-holder-head-border"></div>
                                <div className="kcvt-h-r-o-holder-content">
                                    {data.intro.objective}
                                </div>
                            </div>

                        </div>
                    }

                </div>

                {
                    /*  <div className="kino-cv-template--header--img">
                        {
                            data.intro && data.intro.picture && data.intro.picture.length > 3 &&
                            <div class="hexagon hexagon2">
                                <div class="hexagon-in1">
                                    <div style={{ backgroundImage: `url(${data.intro.picture})` }} class="hexagon-in2"></div>
                                </div>
                            </div>
                        }
                    </div>*/
                }

            </div>



            <div className="kino-cv-template--header-sub">
                {
                    data.intro && data.intro.phone &&
                    <div className="kino-sh-s">
                        <span className="kino-sh-s-i"><MdPhone size={isMobile ? 10 : 20} /></span>
                        <span className="kino-sh-s-c">{data.intro.phone}</span>
                    </div>
                }

                {
                    data.intro && data.intro.email &&
                    <div className="kino-sh-s">
                        <span className="kino-sh-s-i"><MdEmail size={isMobile ? 10 : 20} /></span>
                        <span className="kino-sh-s-c">{data.intro.email}</span>
                    </div>
                }

                {
                    data.intro && (data.intro.country || data.intro.city || data.intro.state) &&
                    <div className="kino-sh-s">
                        <span className="kino-sh-s-i"><FaMapMarked size={isMobile ? 10 : 20} /></span>
                        <span className="kino-sh-s-c">{getAddress()}</span>
                    </div>
                }

                {
                    data.intro && data.socialAccounts && data.socialAccounts.personalSite && data.socialAccounts.personalSite.length > 0 &&
                    <div className="kino-sh-s">
                        <span className="kino-sh-s-i"><FaGlobe size={isMobile ? 10 : 20} /></span>
                        <span className="kino-sh-s-c"><a href={data.socialAccounts.personalSite}>{data.socialAccounts.personalSite.replace(/https:\/\//ig, '').replace(/http:\/\//ig, '')}</a></span>
                    </div>
                }

            </div>

            <div className="content-wrapper">
                <div className="left-sidebar">

                    <div className="kino-cv-template--single-section">
                        <div className="kino-cv-template--single-section--header">
                            <h1 className="kino-cv-template--single-section--heading">Contact</h1>
                            <div className="kino-cv-template--single-section--header-underline"></div>
                        </div>
                        <div className="kino-cv-template--single-section--content">
                            <div className="kino-cv-template--single-section--icon-list">
                                {
                                    data.intro && data.intro.phone &&
                                    <div className="kino-cv-template--single-section--icon-list-single">
                                        <span className="kino-cv-template--single-section--icon-list-single-icon">
                                            <MdPhone size={isMobile ? 10 : 20} />
                                        </span>
                                        <span className="kino-cv-template--single-section--icon-list-single-content">{data.intro.phone}</span>
                                    </div>
                                }

                                {
                                    data.intro && data.intro.email &&
                                    <div className="kino-cv-template--single-section--icon-list-single">
                                        <span className="kino-cv-template--single-section--icon-list-single-icon">
                                            <MdEmail size={isMobile ? 10 : 20} />
                                        </span>
                                        <span className="kino-cv-template--single-section--icon-list-single-content">{data.intro.email}</span>
                                    </div>
                                }

                                {
                                    data.intro && (data.intro.country || data.intro.city || data.intro.state) &&
                                    <div className="kino-cv-template--single-section--icon-list-single">
                                        <span className="kino-cv-template--single-section--icon-list-single-icon">
                                            <FaMapMarked size={isMobile ? 10 : 20} />
                                        </span>
                                        <span className="kino-cv-template--single-section--icon-list-single-content">{getAddress()}</span>
                                    </div>
                                }
                            </div>

                        </div>
                    </div>

                    {
                        showSocialAccounts() &&
                        <>
                            <div className="kino-cv-template--single-section">
                                <div className="kino-cv-template--single-section--header">
                                    <h1 className="kino-cv-template--single-section--heading">Social</h1>
                                    <div className="kino-cv-template--single-section--header-underline"></div>
                                </div>
                                <div className="kino-cv-template--single-section--content">
                                    <div className="kino-cv-template--single-section--icon-list">
                                        {
                                            data.socialAccounts.facebook &&
                                            <div className="kino-cv-template--single-section--icon-list-single">
                                                <span className="kino-cv-template--single-section--icon-list-single-icon">
                                                    <FaFacebook size={isMobile ? 10 : 20} />
                                                </span>
                                                <span className="kino-cv-template--single-section--icon-list-single-content">{data.socialAccounts.facebook}</span>
                                            </div>
                                        }

                                        {
                                            data.socialAccounts.twitter &&
                                            <div className="kino-cv-template--single-section--icon-list-single">
                                                <span className="kino-cv-template--single-section--icon-list-single-icon">
                                                    <FaTwitter size={isMobile ? 10 : 20} />
                                                </span>
                                                <span className="kino-cv-template--single-section--icon-list-single-content">{data.socialAccounts.twitter}</span>
                                            </div>
                                        }

                                        {
                                            data.socialAccounts.linkedIn &&
                                            <div className="kino-cv-template--single-section--icon-list-single">
                                                <span className="kino-cv-template--single-section--icon-list-single-icon">
                                                    <FaLinkedin size={isMobile ? 10 : 20} />
                                                </span>
                                                <span className="kino-cv-template--single-section--icon-list-single-content">{data.socialAccounts.linkedIn}</span>
                                            </div>
                                        }

                                        {
                                            data.socialAccounts.github &&
                                            <div className="kino-cv-template--single-section--icon-list-single">
                                                <span className="kino-cv-template--single-section--icon-list-single-icon">
                                                    <FaGithub size={isMobile ? 10 : 20} />
                                                </span>
                                                <span className="kino-cv-template--single-section--icon-list-single-content">{data.socialAccounts.github}</span>
                                            </div>
                                        }

                                        {
                                            data.socialAccounts.personalSite &&
                                            <div className="kino-cv-template--single-section--icon-list-single">
                                                <span className="kino-cv-template--single-section--icon-list-single-icon">
                                                    <FaGlobe size={isMobile ? 10 : 20} />
                                                </span>
                                                <span className="kino-cv-template--single-section--icon-list-single-content"><a href={data.socialAccounts.personalSite}>{data.socialAccounts.personalSite.replace(/https:\/\//ig, '').replace(/http:\/\//ig, '')}</a></span>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </>
                    }

                    {
                        data.skills && data.skills.length > 0 &&
                        <>
                            <div className="kino-cv-template--single-section">
                                <div className="kino-cv-template--single-section--header">
                                    <h1 className="kino-cv-template--single-section--heading">Skills</h1>
                                    <div className="kino-cv-template--single-section--header-underline"></div>
                                </div>
                                <div className="kino-cv-template--single-section--content">
                                    <div className="kino-cv-template--single-section--skill-list">
                                        {
                                            data.skills.map(s =>
                                                <div className="kino-cv-template--single-section--skill-list-single">
                                                    <span className="kino-cv-template--single-section--skill-list-single-skill">
                                                        {s.title || ''}
                                                    </span>
                                                    <span className="kino-cv-template--single-section--skill-list-single-stars">{getStars(s.grip || 0)}</span>
                                                </div>
                                            )
                                        }

                                    </div>
                                </div>
                            </div>
                        </>
                    }


                    {
                        data.languages && data.languages.length > 0 &&
                        <>
                            <div className="kino-cv-template--single-section">
                                <div className="kino-cv-template--single-section--header">
                                    <h1 className="kino-cv-template--single-section--heading">Languages</h1>
                                    <div className="kino-cv-template--single-section--header-underline"></div>
                                </div>
                                <div className="kino-cv-template--single-section--content">
                                    <div className="kino-cv-template--single-section--language-list">
                                        {
                                            data.languages.map(lan =>
                                                <div className="kino-cv-template--single-section--language-list-single">
                                                    <div className="progress-circle-container">
                                                        <div className={`progress-circle progress-${langLevels[lan.level || 'conversational']}`}><span>{langLevels[lan.level || 'conversational']}</span></div>
                                                    </div>
                                                    <span className="kino-cv-template--single-section--language-list-single-content">
                                                        {lan.title || ''}
                                                    </span>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </>
                    }

                    {
                        data.keyAssets && data.keyAssets.length > 0 &&
                        <>
                            <div className="kino-cv-template--single-section">
                                <div className="kino-cv-template--single-section--header">
                                    <h1 className="kino-cv-template--single-section--heading">Key Assets</h1>
                                    <div className="kino-cv-template--single-section--header-underline"></div>
                                </div>
                                <div className="kino-cv-template--single-section--content">
                                    <div className="kino-cv-template--single-section--keyassets-list">
                                        {
                                            data.keyAssets.map(ka =>
                                                <div className="kino-cv-template--single-section--skill-list-single">
                                                    <span className="kino-cv-template--single-section--skill-list-single-skill">
                                                        {ka.title || ''}
                                                    </span>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                </div>

                <div className="main-content">

                    <div className="main-content-wrapper">
                        {
                            data.employments && data.employments.length > 0 &&
                            <div className="main-content--single-section">
                                <h1 className="main-content--single-section-heading">
                                    <span>Work Experience</span>
                                    <span><ImOffice size={isMobile ? 10 : 20} /></span>
                                </h1>
                                <div className="main-content--single-section-content job-list">
                                    {
                                        data.employments.map(emp =>
                                            <div className="main-content--single-section-content-single-job">
                                                <div className="main-content--single-section-content-single-job-company">{`${emp.employer} |` || ''}  {`${formatDate(emp.startDate)} - ${emp.currentEmployment ? 'Present' : formatDate(emp.endDate)}`}</div>
                                                <div className="main-content--single-section-content-single-job-title">{emp.jobTitle || ''}</div>
                                                <div className="main-content--single-section-content-single-job-description">
                                                    {emp.description || ''}
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        }

                        {
                            data.educations && data.educations.length > 0 &&
                            <div className="main-content--single-section">
                                <h1 className="main-content--single-section-heading">
                                    <span>Education</span>
                                    <span><IoMdSchool size={isMobile ? 10 : 20} /></span>
                                </h1>
                                <div className="main-content--single-section-content education-list">
                                    {
                                        data.educations.map(ed =>
                                            <div className="main-content--single-section-content-single-education">
                                                <div className="main-content--single-section-content-single-education-company">{`${ed.schoolName} |` || ''} {`${formatDate(ed.startDate)} - ${ed.currentSchool ? 'Present' : formatDate(ed.endDate)}`}</div>
                                                <div className="main-content--single-section-content-single-education-title">{ed.degree || ''}</div>
                                                <div className="main-content--single-section-content-single-education-description">
                                                    {ed.details || ''}
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        }
                    </div>

                </div>
            </div>
        </div>
    )
}
